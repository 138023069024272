import React, {useEffect, useContext, useState} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import MedicForm from './paginas/doctor/MedicForm.jsx'
import MedicalSchedule from './paginas/doctor/MedicalSchedule.jsx'
import ChooseLocation from './componentes/ChooseLocation.jsx'
import {
  CrearUsuarioPortalCliente,
  VerAtencionesEjecutivo,
  DetalleAtencionEjecutivo,
  GestionUsuariosPortalCliente,
} from './paginas/ejecutivoComercial'
import {
  CrearEmpresa,
  CrearEmpresaHija,
  EditCompany,
  EditarEmpresaHija,
} from './paginas/empresa'
import {CreateUserService, ConsultPatient} from './paginas/receptionist'
import {
  AgendaMedico,
  Examen,
  ExamenMedicoPreingreso,
  SolicitarModificacionDeExamen,
  VerModificarExamenesAtendidos,
  ReporteDeAtencionDiaria,
} from './paginas/Medico'
import {
  CrearPlantilla,
  GestionAsignacionPlantillaAServicio,
  AsignarPlantillaAServicio,
  GestionFirma,
  GestionRol,
  EditarFirma,
  EditarRol,
  ControlVersionPlantillas,
  ControlVersionPlantilla,
  CreadorDeReportes,
} from './paginas/administrador'
import {CrearServicio, EditarServicio} from './paginas/servicio'
import {CrearTarifario, EditarTarifario} from './paginas/tarifario'
import {
  ConsultaEstadoFacturacion,
  AgendaRegistro,
  AsignarTurno,
  CierreDeCaja,
  ConsultaTarifarioParticular,
  FinalizarRecarga,
  HistoriaClinica,
  LlamarPaciente,
  RecargarSaldoEnSede,
  ModificarAtencionEjecutada,
  ModificarPacientes,
  RegistrarPaciente,
  GeneradorDeInformes,
} from './paginas/recepcionista'
import {
  DefinirResolucionFirmaElectronica,
  VerResolucionesFirmaElectronica,
} from './paginas/resolucionFirmaElectronica'
import {
  CargarResultadosAliado,
  EditarResultadosAliado,
} from './paginas/aliadoAdministrador'
import {CrearPortafolio, EditarPortafolio} from './paginas/portafolio'
import {
  FacturasPendientesCorreccionSede,
  CorreccionErroresTransmisionFirmaElectronica,
} from './paginas/gestionFacturacion'
import {RegistrarResultados, TomaDeMuestra} from './paginas/bacteriologia'
import {
  HabilitarModificarExamenesAtendidos,
  VerReporteEjecucionDiariaSede,
  VerReporteEjecucionPorEmpresa,
  UnificarDatosPaciente,
} from './paginas/jefeDeSede'
import {RegenerarCierreCaja, ReporteDeVentas} from './paginas/gerenteGeneral'
import {
  CrearNotaCredito,
  GestionDocumentacionElectronica,
} from './paginas/administradorContable'
import {Seleccion} from './paginas/compartida'
import {AccesoDenegado} from './paginas/autorizacion'
import {
  AgendaMedicoConContexto,
  AgendaRegistroConContexto,
  AsignarTurnoConContexto,
  BacteriologoConContexto,
  CargarDatosAliadoConContexto,
  PlantillaConContexto,
  CierreCajaConContexto,
  RegistroContexto,
  EmpresaConContexto,
  TarifarioConContexto,
  ContextApplication,
  LlamarPacienteConContexto,
  MedicoOcupacionalConContexto,
  ServicioContexto,
  FormularioConContexto,
  PortafolioConContexto,
  ResolucionFirmaElectronicaConContexto,
  JefeDeSedeConContexto,
  GestionFacturacionConContexto,
  ControlVersionPlantillasConContexto,
  PaqueteConContexto,
  ReporteConContexto,
  GestionOtrosServiciosConContexto,
  UnificacionDeDatosConContexto,
} from './contexto'

import {NotaCreditoConContexto} from './contexto/ContextoNotaCredito.js'
import {
  AutorizarComponente,
  FormularioModificarExamenesAtendidos,
  MainPage,
  ModificarOCancelarAtencion,
} from './componentes'
import {ROLES_APP, RUTAS_APP} from './constantes'
import {CrearPaquete, EditarPaquete} from './paginas/paquete'
import MotivoModificacion from './paginas/jefeDeSede/ResumenTransaccionModificar.jsx'
import VerSaldoDelCliente from './paginas/saldo/VerSaldoDelCliente.jsx'
import InformeCondicionSalud from './paginas/profesionalsst/condicionSalud/InformeCondicionSalud.jsx'
import {
  GeneradorDeReportes,
  GeneradorDeReportesVersionAntigua,
} from './paginas/administrador'
import {useFeatureIsOn} from '@growthbook/growthbook-react'
import {TEXTO_FEATURE_FLAGS} from './constantes/featureFlags.js'
import {GestionDePreciosOtrosServicios} from './paginas/gerenteComercial/index.jsx'
import CargaBancos from './paginas/tesorero/CargaBancos.jsx'
import {ConfirmacionDePagos} from './paginas/ConfirmacionDePagos'
import {FacturacionConfirmacionDePagos} from './paginas/FacturacionDeConfirmacionDePagos'
import Contabilidad from './paginas/AuxContable/Contabilidad/index.jsx'
import FuenteDatos from './paginas/administrador/fuente_datos/FuenteDatos.jsx'

const Router = () => {
  const habilitarNuevaVersionReportes = useFeatureIsOn(
    TEXTO_FEATURE_FLAGS.NUEVO_UI_REPORTES
  )

  const habilitarRegenerarCierreCaja = useFeatureIsOn(
    TEXTO_FEATURE_FLAGS.REGENERAR_CIERRE_CAJA
  )

  const contexto = useContext(ContextApplication)
  useEffect(() => {
    contexto.setModal({abierto: false, titulo: '', contenido: '', botones: []})
  }, [])

  return (
    <Switch>
      {/* Comercial */}
      <Route path="/services">
        <AutorizarComponente roles={ROLES_APP.COMERCIAL}>
          <ServicioContexto>
            <CrearServicio />
          </ServicioContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/createservice">
        <AutorizarComponente roles={ROLES_APP.COMERCIAL}>
          <CreateUserService />
        </AutorizarComponente>
      </Route>
      <Route exact path="/editarservicio">
        <AutorizarComponente
          roles={[ROLES_APP.COMERCIAL, ROLES_APP.GERENTE_FINANCIERO]}
        >
          <ServicioContexto>
            <EditarServicio />
          </ServicioContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.COMERCIAL.CREAR_PAQUETES}>
        <AutorizarComponente roles={[ROLES_APP.COMERCIAL, ROLES_APP.LICITADOR]}>
          <PaqueteConContexto>
            <CrearPaquete />
          </PaqueteConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.COMERCIAL.EDITAR_PAQUETE}>
        <AutorizarComponente
          roles={[
            ROLES_APP.COMERCIAL,
            ROLES_APP.LICITADOR,
            ROLES_APP.GERENTE_FINANCIERO,
          ]}
        >
          <PaqueteConContexto>
            <EditarPaquete />
          </PaqueteConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/crearempresa">
        <AutorizarComponente
          roles={[
            ROLES_APP.COMERCIAL,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.LICITADOR,
          ]}
        >
          <EmpresaConContexto>
            <CrearEmpresa />
          </EmpresaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/editarempresa">
        <AutorizarComponente
          roles={[
            ROLES_APP.COMERCIAL,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
          ]}
        >
          <EmpresaConContexto>
            <EditCompany />
          </EmpresaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/crearempresahija">
        <AutorizarComponente roles={[ROLES_APP.COMERCIAL, ROLES_APP.LICITADOR]}>
          <EmpresaConContexto>
            <CrearEmpresaHija />
          </EmpresaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/editarempresahija">
        <AutorizarComponente
          roles={[
            ROLES_APP.COMERCIAL,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
          ]}
        >
          <EmpresaConContexto>
            <EditarEmpresaHija />
          </EmpresaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/creartarifario">
        <AutorizarComponente roles={[ROLES_APP.COMERCIAL, ROLES_APP.LICITADOR]}>
          <TarifarioConContexto>
            <CrearTarifario />
          </TarifarioConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/editartarifario">
        <AutorizarComponente
          roles={[
            ROLES_APP.COMERCIAL,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
          ]}
        >
          <TarifarioConContexto>
            <EditarTarifario />
          </TarifarioConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.PORTAFOLIO.CREAR}>
        <AutorizarComponente roles={ROLES_APP.COMERCIAL}>
          <PortafolioConContexto>
            <CrearPortafolio />
          </PortafolioConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.PORTAFOLIO.EDITAR}>
        <AutorizarComponente roles={ROLES_APP.COMERCIAL}>
          <PortafolioConContexto>
            <EditarPortafolio />
          </PortafolioConContexto>
        </AutorizarComponente>
      </Route>
      {/* Recepcion */}
      <Route exact path="/seleccionarsede">
        <AutorizarComponente
          roles={[ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO]}
        >
          <RegistroContexto>
            <Seleccion SeleccionSede="Consultorio" />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/asignarturno">
        <AutorizarComponente
          roles={[ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO]}
        >
          <AsignarTurnoConContexto>
            <AsignarTurno />
          </AsignarTurnoConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/llamarpaciente">
        <AutorizarComponente
          roles={[ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO]}
        >
          <LlamarPacienteConContexto>
            <LlamarPaciente />
          </LlamarPacienteConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/registrarpaciente">
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.RECEPCION_ALIADO,
          ]}
        >
          <RegistroContexto>
            <RegistrarPaciente />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/agendaregistro">
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.RECEPCION_ALIADO,
          ]}
        >
          <AgendaRegistroConContexto>
            <AgendaRegistro />
          </AgendaRegistroConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.RECEPCION.HISTORIA_CLINICA}>
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.ADMINISTRADOR,
            ROLES_APP.RECEPCION_ALIADO,
          ]}
        >
          <HistoriaClinica />
        </AutorizarComponente>
      </Route>
      <Route exact path="/modificarpacientes">
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.ADMINISTRADOR,
            ROLES_APP.RECEPCION_ALIADO,
          ]}
        >
          <RegistroContexto>
            <ModificarPacientes />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.RECEPCION.MODIFICAR_ATENCION_EJECUTADA}>
        <AutorizarComponente roles={ROLES_APP.JEFE_SEDE}>
          <RegistroContexto>
            <ModificarAtencionEjecutada />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.JEFE_DE_SEDE.RESUMEN_TRANSACCION_MODIFICAR}>
        <AutorizarComponente roles={ROLES_APP.JEFE_SEDE}>
          <RegistroContexto>
            <MotivoModificacion />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={
          RUTAS_APP.RECEPCION.MODIFICAR_CANCELAR_ATENCION_EMPRESA_HIJA_CARGO
        }
      >
        <AutorizarComponente roles={[ROLES_APP.RECEPCION, ROLES_APP.JEFE_SEDE]}>
          <RegistroContexto>
            <ModificarOCancelarAtencion />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.GESTION_FACTURACION.FACTURAS_PENDIENTES_CORRECCION_SEDE}
      >
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <GestionFacturacionConContexto>
            <FacturasPendientesCorreccionSede />
          </GestionFacturacionConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.RECEPCION.RECARGAR_SALDO_EN_SEDE}>
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <RegistroContexto>
            <RecargarSaldoEnSede />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.RECEPCION.FINALIZAR_RECARGA}>
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <RegistroContexto>
            <FinalizarRecarga />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={
          RUTAS_APP.GESTION_FACTURACION
            .CORRECCION_ERRORES_TRANSMISION_FACTURA_ELECTRONICA
        }
      >
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.AUDITOR,
            ROLES_APP.FACTURADOR,
          ]}
        >
          <GestionFacturacionConContexto>
            <CorreccionErroresTransmisionFirmaElectronica />
          </GestionFacturacionConContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.GESTION_FACTURACION.CONSULTA_ESTADO_FACTURACION}
      >
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <GestionFacturacionConContexto>
            <ConsultaEstadoFacturacion />
          </GestionFacturacionConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/cierrecaja">
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <CierreCajaConContexto>
            <CierreDeCaja />
          </CierreCajaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.RECEPCION.CONSULTA_TARIFARIO_PARTICULAR}>
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <ConsultaTarifarioParticular />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.RECEPCION.GENERADOR_DE_INFORMES}>
        <AutorizarComponente roles={ROLES_APP.RECEPCION}>
          <GeneradorDeInformes />
        </AutorizarComponente>
      </Route>

      {/* tesorero */}

      <Route exact path={RUTAS_APP.TESORERO.CARGA_ARCHIVOS_BANCOS}>
        <AutorizarComponente
          roles={[
            ROLES_APP.TESORERO,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.AUXILIAR_TESORERO,
          ]}
        >
          <RegistroContexto>
            <CargaBancos />
          </RegistroContexto>
        </AutorizarComponente>
      </Route>

      {/* Administrador */}
      <Route exact path={RUTAS_APP.ADMINISTRADOR_GATO.GESTION_ROLES}>
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <GestionRol />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.ADMINISTRADOR_GATO.EDITAR_ROL}>
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <EditarRol />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.ADMINISTRADOR_GATO.GESTION_FIRMA}>
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <GestionFirma />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.ADMINISTRADOR_GATO.GESTION_DE_REPORTES}>
        <AutorizarComponente
          roles={[
            ROLES_APP.ADMINISTRADOR,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.AUXILIAR_ADMINISTRATIVO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.PROFESIONAL_SST,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
            ROLES_APP.AUXILIAR_TESORERO,
          ]}
        >
          <ReporteConContexto>
            {!habilitarNuevaVersionReportes ? (
              <GeneradorDeReportesVersionAntigua />
            ) : (
              <GeneradorDeReportes />
            )}
          </ReporteConContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.ADMINISTRADOR_GATO.GESTION_DE_REPORTES_NUEVO}
      >
        <ReporteConContexto>
          <GeneradorDeReportes />
        </ReporteConContexto>
      </Route>
      <Route exact path={RUTAS_APP.ADMINISTRADOR_GATO.CREAR_REPORTES}>
        <AutorizarComponente
          roles={[
            ROLES_APP.ADMINISTRADOR,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.AUXILIAR_ADMINISTRATIVO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.PROFESIONAL_SST,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
            ROLES_APP.AUXILIAR_TESORERO,
          ]}
        >
          <ReporteConContexto>
            <CreadorDeReportes />
          </ReporteConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.ADMINISTRADOR_GATO.EDITAR_FIRMA}>
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <EditarFirma />
        </AutorizarComponente>
      </Route>
      {/** MOTOR DE PLANTILLAS ADMINISTRADOR*/}
      <Route exact path="/crearplantilla">
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <PlantillaConContexto>
            <FormularioConContexto>
              <CrearPlantilla />
            </FormularioConContexto>
          </PlantillaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/gestion-asignacion-plantilla-a-servicio">
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <GestionAsignacionPlantillaAServicio />
        </AutorizarComponente>
      </Route>
      <Route exact path="/asignar-plantilla-a-servicio">
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <AsignarPlantillaAServicio />
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={
          RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .CONTROL_VERSION_DE_PLANTILLAS
        }
      >
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <ControlVersionPlantillasConContexto>
            <ControlVersionPlantillas />
          </ControlVersionPlantillasConContexto>
        </AutorizarComponente>
      </Route>

      <Route
        exact
        path={
          RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .CREAR_EDITAR_FUENTE_DATOS
        }
      >
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <FuenteDatos />
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={
          RUTAS_APP.ADMINISTRADOR_GATO.MOTOR_DE_PLATILLAS
            .CONTROL_VERSION_PLANTILLA
        }
      >
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR}>
          <ControlVersionPlantillasConContexto>
            <PlantillaConContexto>
              <FormularioConContexto>
                <ControlVersionPlantilla />
              </FormularioConContexto>
            </PlantillaConContexto>
          </ControlVersionPlantillasConContexto>
        </AutorizarComponente>
      </Route>
      {/* Administrador Contable */}
      <Route exact path={RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.RAIZ}>
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR_CONTABLE}>
          <ResolucionFirmaElectronicaConContexto>
            <VerResolucionesFirmaElectronica />
          </ResolucionFirmaElectronicaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.GESTION_FACTURA_ELECTRONICA}>
        <AutorizarComponente
          roles={[
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.FACTURADOR,
            ROLES_APP.AUDITOR,
          ]}
        >
          <ResolucionFirmaElectronicaConContexto>
            <GestionDocumentacionElectronica />
          </ResolucionFirmaElectronicaConContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={[
          RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.CARGAR,
          RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.MODIFICAR,
        ]}
      >
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR_CONTABLE}>
          <ResolucionFirmaElectronicaConContexto>
            <DefinirResolucionFirmaElectronica />
          </ResolucionFirmaElectronicaConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.ADMINISTRADOR_CONTABLE.CREAR_NOTA_CREDITO}>
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR_CONTABLE}>
          <NotaCreditoConContexto>
            <CrearNotaCredito />
          </NotaCreditoConContexto>
        </AutorizarComponente>
      </Route>
      {/* Administrador Aliado */}
      <Route exact path="/cargarresultadosaliado">
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR_ALIADO}>
          <CargarDatosAliadoConContexto>
            <CargarResultadosAliado />
          </CargarDatosAliadoConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path="/editarresultadosaliado">
        <AutorizarComponente roles={ROLES_APP.ADMINISTRADOR_ALIADO}>
          <CargarDatosAliadoConContexto>
            <EditarResultadosAliado />
          </CargarDatosAliadoConContexto>
        </AutorizarComponente>
      </Route>
      {/* Especialidades */}
      <Route exact path="/agendamedico">
        <AutorizarComponente
          roles={[
            ROLES_APP.MEDICO_OCUPACIONAL,
            ROLES_APP.AUXILIAR_ENFERMERIA,
            ROLES_APP.BACTERIOLOGO,
            ROLES_APP.FONOAUDIOLOGO,
            ROLES_APP.OPTOMETRA,
            ROLES_APP.PSICOLOGO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.FISIOTERAPEUTA,
          ]}
        >
          <AgendaMedicoConContexto>
            <AgendaMedico />
          </AgendaMedicoConContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.COMPARTIDO.VER_MODIFICAR_EXAMENES_ATENDIDOS_SOLICITUDES}
      >
        <AutorizarComponente
          roles={[
            ROLES_APP.MEDICO_OCUPACIONAL,
            ROLES_APP.AUXILIAR_ENFERMERIA,
            ROLES_APP.FONOAUDIOLOGO,
            ROLES_APP.OPTOMETRA,
            ROLES_APP.PSICOLOGO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.BACTERIOLOGO,
            ROLES_APP.FISIOTERAPEUTA,
          ]}
        >
          <VerModificarExamenesAtendidos />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.COMPARTIDO.SOLICITAR_MODIFICAR_EXAMENES}>
        <AutorizarComponente
          roles={[
            ROLES_APP.MEDICO_OCUPACIONAL,
            ROLES_APP.AUXILIAR_ENFERMERIA,
            ROLES_APP.FONOAUDIOLOGO,
            ROLES_APP.OPTOMETRA,
            ROLES_APP.PSICOLOGO,
            ROLES_APP.BACTERIOLOGO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.FISIOTERAPEUTA,
          ]}
        >
          <SolicitarModificacionDeExamen />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.COMPARTIDO.REPORTE_ATENCION_DIARIA}>
        <AutorizarComponente
          roles={[
            ROLES_APP.MEDICO_OCUPACIONAL,
            ROLES_APP.AUXILIAR_ENFERMERIA,
            ROLES_APP.BACTERIOLOGO,
            ROLES_APP.FONOAUDIOLOGO,
            ROLES_APP.OPTOMETRA,
            ROLES_APP.PSICOLOGO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.FISIOTERAPEUTA,
          ]}
        >
          <ReporteDeAtencionDiaria />
        </AutorizarComponente>
      </Route>
      {/* Bacteriologo */}
      <Route exact path={RUTAS_APP.BACTERIOLOGIA.RESULTADOS_EXAMEN}>
        <AutorizarComponente roles={ROLES_APP.BACTERIOLOGO}>
          <BacteriologoConContexto>
            <RegistrarResultados />
          </BacteriologoConContexto>
        </AutorizarComponente>
      </Route>
      {/* Jefe De Sede */}
      <Route
        exact
        path={RUTAS_APP.JEFE_DE_SEDE.HABILITAR_MODIFICAR_EXAMENES_ATENDIDOS}
      >
        <AutorizarComponente
          roles={[ROLES_APP.JEFE_SEDE, ROLES_APP.GERENTE_GENERAL]}
        >
          <JefeDeSedeConContexto>
            <HabilitarModificarExamenesAtendidos />
          </JefeDeSedeConContexto>
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.JEFE_DE_SEDE.REPORTE_EJECUCION_DIARIA_POR_SEDE}
      >
        <AutorizarComponente roles={[ROLES_APP.JEFE_SEDE, ROLES_APP.AUDITOR]}>
          <JefeDeSedeConContexto>
            <VerReporteEjecucionDiariaSede />
          </JefeDeSedeConContexto>
        </AutorizarComponente>
      </Route>
      <Route path={RUTAS_APP.JEFE_DE_SEDE.VER_MODIFICAR_EXAMENES_ATENDIDOS}>
        <AutorizarComponente
          roles={[ROLES_APP.JEFE_SEDE, ROLES_APP.GERENTE_GENERAL]}
        >
          <JefeDeSedeConContexto>
            <FormularioModificarExamenesAtendidos />
          </JefeDeSedeConContexto>
        </AutorizarComponente>
      </Route>
      {habilitarRegenerarCierreCaja ? (
        <Route exact path={RUTAS_APP.GERENTE_GENERAL.VER_REGENERAR_CIERRE_CAJA}>
          <AutorizarComponente
            roles={[
              ROLES_APP.GERENTE_GENERAL,
              ROLES_APP.ADMINISTRADOR,
              ROLES_APP.JEFE_SEDE,
              ROLES_APP.AUDITOR,
            ]}
          >
            <RegenerarCierreCaja />
          </AutorizarComponente>
        </Route>
      ) : null}

      <Route exact path={RUTAS_APP.GERENTE_GENERAL.VER_REGENERAR_CIERRE_CAJA}>
        <AutorizarComponente
          roles={[
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.ADMINISTRADOR,
            ROLES_APP.JEFE_SEDE,
          ]}
        >
          <RegenerarCierreCaja />
        </AutorizarComponente>
      </Route>

      <Route exact path={RUTAS_APP.JEFE_DE_SEDE.RESUMEN_POR_ENTIDAD}>
        <AutorizarComponente roles={[ROLES_APP.JEFE_SEDE, ROLES_APP.AUDITOR]}>
          <JefeDeSedeConContexto>
            <VerReporteEjecucionPorEmpresa />
          </JefeDeSedeConContexto>
        </AutorizarComponente>
      </Route>
      {/* Gerente General */}
      <Route exact path={RUTAS_APP.GERENTE_GENERAL.REPORTE_DE_VENTAS}>
        <AutorizarComponente
          roles={[
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_COMERCIAL,
          ]}
        >
          <ReporteDeVentas />
        </AutorizarComponente>
      </Route>
      {/* TODO Pendientes agregar autorización */}
      <Route path="/medicform">
        <MedicForm />
      </Route>
      <Route path="/createpatient">
        <ConsultPatient />
      </Route>
      <Route path="/medicalschedule">
        <MedicalSchedule />
      </Route>
      <Route path="/chooselocation">
        <ChooseLocation />
      </Route>
      {/* MEDICO */}
      <Route path="/examenMedicoPreIngreso">
        <MedicoOcupacionalConContexto>
          <ExamenMedicoPreingreso />
        </MedicoOcupacionalConContexto>
      </Route>
      <Route path="/examen">
        <FormularioConContexto>
          <Examen />
        </FormularioConContexto>
      </Route>
      <Route path={RUTAS_APP.BACTERIOLOGIA.TOMA_DE_MUESTRAS}>
        <BacteriologoConContexto>
          <TomaDeMuestra />
        </BacteriologoConContexto>
      </Route>
      <Route exact path={RUTAS_APP.AUTORIZACION.ACCESO_DENEGADO}>
        <AccesoDenegado />
      </Route>
      {/**EJECUTIVA COMERCIAL */}
      <Route
        exact
        path={RUTAS_APP.EJECUTIVA_COMERCIAL.GESTION_USUARIOS_PORTAL_CLIENTE}
      >
        <AutorizarComponente
          roles={[
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.LICITADOR,
          ]}
        >
          <GestionUsuariosPortalCliente />
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.EJECUTIVA_COMERCIAL.CREAR_USUARIO_PORTAL_CLIENTE}
      >
        <AutorizarComponente
          roles={[
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.LICITADOR,
          ]}
        >
          <CrearUsuarioPortalCliente />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.EJECUTIVA_COMERCIAL.VER_ATENCIONES}>
        <AutorizarComponente
          roles={[
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.AUXILIAR_ADMINISTRATIVO,
            ROLES_APP.PROFESIONAL_SST,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
          ]}
        >
          <VerAtencionesEjecutivo />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.EJECUTIVA_COMERCIAL.VER_DETALLE_DE_ATENCION}>
        <AutorizarComponente
          roles={[
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.AUXILIAR_ADMINISTRATIVO,
            ROLES_APP.PROFESIONAL_SST,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.GERENTE_COMERCIAL,
            ROLES_APP.FACTURADOR,
            ROLES_APP.LICITADOR,
            ROLES_APP.AUDITOR,
            ROLES_APP.GERENTE_FINANCIERO,
          ]}
        >
          <DetalleAtencionEjecutivo />
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.PROFESIONAL_SST.INFORME_CONDICIONES_DE_SALUD}
      >
        <AutorizarComponente roles={[ROLES_APP.PROFESIONAL_SST]}>
          <InformeCondicionSalud />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.COMPARTIDO.VER_SALDO_DEL_CLIENTE}>
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.AUXILIAR_ADMINISTRATIVO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.COMERCIAL,
            ROLES_APP.AUDITOR,
            ROLES_APP.AUXILIAR_TESORERO,
          ]}
        >
          <VerSaldoDelCliente />
        </AutorizarComponente>
      </Route>
      {/* GERENTE COMERCIAL */}
      <Route exact path={RUTAS_APP.GERENTE_COMERCIAL.GESTION_OTROS_SERVICIOS}>
        <AutorizarComponente roles={[ROLES_APP.GERENTE_COMERCIAL]}>
          <GestionOtrosServiciosConContexto>
            <GestionDePreciosOtrosServicios />
          </GestionOtrosServiciosConContexto>
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.COMPARTIDO.VER_SALDO_DEL_CLIENTE}>
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.EJECUTIVO_COMERCIAL,
            ROLES_APP.ADMINISTRADOR_CONTABLE,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.AUXILIAR_ADMINISTRATIVO,
            ROLES_APP.GERENTE_GENERAL,
            ROLES_APP.COMERCIAL,
          ]}
        >
          <VerSaldoDelCliente />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.CONFIRMACION_PAGOS.CONFIRMACION_DE_PAGOS}>
        <AutorizarComponente
          roles={[
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.TESORERO,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.FACTURADOR,
            ROLES_APP.AUXILIAR_TESORERO,
          ]}
        >
          <ConfirmacionDePagos />
        </AutorizarComponente>
      </Route>
      <Route
        exact
        path={RUTAS_APP.CONFIRMACION_PAGOS.FACTURACION_CONFIRMACION_DE_PAGOS}
      >
        <AutorizarComponente roles={[ROLES_APP.FACTURADOR, ROLES_APP.TESORERO]}>
          <FacturacionConfirmacionDePagos />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.CONFIRMACION_PAGOS.CONTABILIDAD}>
        <AutorizarComponente
          roles={[
            ROLES_APP.TESORERO,
            ROLES_APP.RECEPCION,
            ROLES_APP.JEFE_SEDE,
            ROLES_APP.FACTURADOR,
            ROLES_APP.AUXILIAR_CONTABLE,
            ROLES_APP.GERENTE_FINANCIERO,
            ROLES_APP.AUXILIAR_TESORERO,
          ]}
        >
          <Contabilidad />
        </AutorizarComponente>
      </Route>
      <Route exact path={RUTAS_APP.JEFE_DE_SEDE.UNIFICAR_DATOS_PACIENTE}>
        <AutorizarComponente roles={[ROLES_APP.JEFE_SEDE]}>
          <UnificacionDeDatosConContexto>
            <UnificarDatosPaciente />
          </UnificacionDeDatosConContexto>
        </AutorizarComponente>
      </Route>
      {/* GENERAL, DEJAR SIEMPRE DE ULTIMO */}
      <Route path="/main">
        <MainPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/main" />
      </Route>
    </Switch>
  )
}
export default Router
