import React, {useState, useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import Input from './Input'
import ListaDesplegable from './ListaDesplegable'
import {regexBasicoV1} from '../../constantes/regex'
import {ObtenerTiposDeDocumento} from '../../cache/servicios-cache'
import {BotonSimple} from '../Botones'
import clsx from 'clsx'

const FiltroUnificarAtenciones = ({funcionSubmit}) => {
  const {
    handleSubmit,
    control,
    trigger,
    formState: {errors},
  } = useForm({
    defaultValues: {
      numeroDocumentoCorrecto: '',
      tipoDeDocumentoCorrecto: '',
      numeroDocumentoIncorrecto: '',
      tipoDeDocumentoIncorrecto: '',
    },
    mode: 'onChange',
  })
  const [tiposDocumento, setTiposDocumento] = useState([])

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      setTiposDocumento(await ObtenerTiposDeDocumento())
    }
    trigger()
    obtenerDatosIniciales()
  }, [])

  return (
    <form
      className="w-full flex justify-center items-end"
      onSubmit={handleSubmit(funcionSubmit)}
    >
      <div className={`w-10/12 flex justify-between items-end flex-wrap mr-16`}>
        <Controller
          name="numeroDocumentoCorrecto"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              estilosContenedor={'w-22% '}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                    Object.keys(errors).includes('numeroDocumentoCorrecto'),
                  'border border-cendiatra focus:border-cendiatra':
                    !Object.keys(errors).includes('numeroDocumentoCorrecto'),
                }
              )}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'N° Documento Correcto*'}
              onChange={onChange}
              valor={value}
            />
          )}
        />
        <Controller
          name="tipoDeDocumentoCorrecto"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-22%'}
              estilosLista={clsx(
                'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                    Object.keys(errors).includes('tipoDeDocumentoCorrecto'),
                  'border border-cendiatra focus:border-cendiatra':
                    !Object.keys(errors).includes('tipoDeDocumentoCorrecto'),
                }
              )}
              titulo={'Tipo Documento Correcto*'}
              opciones={tiposDocumento}
              valor={value}
            />
          )}
        />
        <Controller
          name="numeroDocumentoIncorrecto"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              estilosContenedor={'w-22%'}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                    Object.keys(errors).includes('numeroDocumentoIncorrecto'),
                  'border border-cendiatra focus:border-cendiatra':
                    !Object.keys(errors).includes('numeroDocumentoIncorrecto'),
                }
              )}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo={'N° Documento Incorrecto*'}
              onChange={onChange}
              valor={value}
            />
          )}
        />
        <Controller
          name="tipoDeDocumentoIncorrecto"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-22%'}
              estilosLista={clsx(
                'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                    Object.keys(errors).includes('tipoDeDocumentoIncorrecto'),
                  'border border-cendiatra focus:border-cendiatra':
                    !Object.keys(errors).includes('tipoDeDocumentoIncorrecto'),
                }
              )}
              titulo={'Tipo de documento Incorrecto*'}
              opciones={tiposDocumento}
              valor={value}
            />
          )}
        />
        <BotonSimple
          texto={'Buscar'}
          estilosBoton={`w-24 h-8 text-white ${
            Object.keys(errors).length ? 'bg-cendiatra-gris-2' : 'bg-btnBg'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px `}
          funcion={null}
          deshabilitado={Object.keys(errors).length}
          tipoDeBoton={'submit'}
        />
      </div>
    </form>
  )
}

export default FiltroUnificarAtenciones
