import React from 'react'
import ReactDom from 'react-dom'

const OVERLAY_STYLE = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  rigth: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const Modal = ({open, children, clasesAdicioneles}) => {
  if (!open) return null
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLE}>
        <div
          className={`${
            clasesAdicioneles || 'w-4/5 lg:w-3/5'
          } bg-white p-12 rounded-md text-center overflow-auto max-h-70vh border border-cendiatra uppercase`}
        >
          {children}
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Modal
