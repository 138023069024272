import React, {useContext, useEffect, useState, useMemo} from 'react'
import Boton from '../../Botones/Boton'
import {useForm, Controller} from 'react-hook-form'
import {ListaDesplegable} from '../../inputs'
import {ContextApplication} from '../../../contexto'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../../Tablas'
import Editar from './Editar'
import clsx from 'clsx'
import BotonConImagen from '../../BotonConImagen'
import closeImg from '../../../imagenes/Salir.svg'
import FuenteDatoOpcion from './FuenteDatoOpcion'

const ElegirVersion = ({
  titulo,
  idFuente,
  referencias,
  version,
  funcionEjecutarSatisfactoria = () => {},
}) => {
  const {setError, getValues, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      nombre: '',
    },
  })

  const [opcionesVersiones, setOpcionesVersiones] = useState([])

  const [edicionHabilitada, setEdicionHabilitada] = useState(false)

  const [referenciasVersion, setReferenciasVersion] = useState([])

  const [versionActiva, setVersionActiva] = useState(1)

  const contextoAplicacion = useContext(ContextApplication)

  useEffect(() => {
    setReferenciasVersion(
      referencias.map((referencia) => ({
        id: referencia.identificacion,
        valor: referencia.nombre,
        version: referencia.version,
      }))
    )
  }, [])

  useEffect(() => {
    setVersionActiva(version)

    const generarArreglo = (n) => {
      return Array.from({length: n}, (_, i) => ({
        Id: i + 1,
        Nombre: i + 1,
      }))
    }
    setOpcionesVersiones(generarArreglo(version + 1))
  }, [])

  const columnas = useMemo(
    () => [
      {
        Header: () => <EncabezadoFondo titulo={'Lista de opciones'} />,
        accessor: 'valor',
        className: 'w-full',
        cellStyles:
          'flex justify-start mx-10 h-10 text-14px text-cendiatra-gris-1',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-lg',
        disableFilters: true,
        Cell: ({row}) => <FuenteDatoOpcion valor={row.original.valor} />,
      },
    ],
    []
  )

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  const editarVersion = () => {
    setEdicionHabilitada(true)
  }

  useEffect(() => {
    if (getValues('nombre').trim() === '') {
      setError('nombre')
    }
  }, [setError, getValues])

  const filtrarVersiones = (value) => {
    if (!value) return
    setVersionActiva(+value)
  }

  const referenciasVersionReciente = useMemo(
    () =>
      referenciasVersion
        .filter((ref) => ref.version === versionActiva)
        .sort((a, b) => a.id - b.id),
    [referenciasVersion, versionActiva]
  )

  return (
    <>
      <div className="w-full flex justify-end h-7 -mt-10">
        <BotonConImagen
          estilosImagen={'h-7'}
          imagen={closeImg}
          medio={true}
          funcionAEjecutar={() => cerrarModal('setModal')}
          textoAlternativo={'cerrar'}
        />
      </div>
      <h2 className="text-cendiatra font-bold text-xl flex flex-col w-full justify-items-start justify-start text-left">
        ID {idFuente}
      </h2>

      <div
        className={`flex justify-center items-end  w-full text-left font-bold text-xl mt-10`}
      >
        {titulo}
      </div>

      <div className="flex flex-row w-full justify-end align-middle items-center mt-5">
        <span className="normal-case text-sm">
          {edicionHabilitada ? 'Versión al Guardar' : 'Versión'}
        </span>
        <Controller
          name="version"
          control={control}
          render={({}) => (
            <ListaDesplegable
              estilosContenedor={'flex mx-2'}
              estilosLista={clsx(
                'appearance-none rounded relative border-cendiatra block w-full p-1.5 border text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
                {
                  'bg-cendiatra-gris-2 pointer-events-none': edicionHabilitada,
                }
              )}
              opciones={
                edicionHabilitada
                  ? opcionesVersiones
                  : opcionesVersiones?.slice(0, -1)
              }
              titulo={``}
              funcion={(e) => filtrarVersiones(e.target.value)}
              valor={edicionHabilitada ? versionActiva + 1 : versionActiva}
              mostrarTextBlanco={edicionHabilitada}
              deshabilitado={edicionHabilitada}
            />
          )}
        />
      </div>
      {!edicionHabilitada ? (
        <div className="w-full flex justify-center items-center flex-wrap mt-5">
          <div className="w-full flex justify-center items-center  bg-white mt-5 shadow-2xl mb-2 h-350 ">
            <TablaConPaginacionYFiltros
              data={referenciasVersionReciente}
              columns={columnas}
              estilosTablaDatos={'overflow-y-auto bg-white rounded-lg h-350'}
              usarSort={false}
            />
          </div>

          <div className="flex w-full mt-5 justify-center">
            <Boton
              titulo="Regresar"
              lineaPrimerCaracter={false}
              habilitado={true}
              funcionCLick={() => cerrarModal('setModal')}
              colorPorDefecto={'bg-grayColor'}
              colorActivo={''}
              tipo={'button'}
              tamanoTexto={'text-cendiatra underline'}
              ancho={'w-2/12'}
              alto={'h-9'}
            />
            <div className="mr-36"></div>
            <Boton
              titulo="Editar"
              habilitado={true}
              funcionCLick={editarVersion}
              colorPorDefecto={'bg-grayColor'}
              colorActivo={'bg-btnBg'}
              tipo={'button'}
              ancho={'w-2/12'}
              alto={'h-9'}
            />
          </div>
        </div>
      ) : (
        <Editar
          idFuente={idFuente}
          titulo={titulo}
          referencias={referenciasVersionReciente}
          version={version}
          setEdicionHabilitada={setEdicionHabilitada}
          funcionEjecutarSatisfactoria={funcionEjecutarSatisfactoria}
        />
      )}
    </>
  )
}

export default ElegirVersion
