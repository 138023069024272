import {useContext, useMemo} from 'react'
import {Boton} from '../../Botones'
import {EncabezadoFondo} from '../../Tablas'
import {ContextApplication} from '../../../contexto'
import ElegirVersion from './ElegirVersion'
import {TablaConPaginacionYFiltros} from '../../Tablas'

export default function TablaFuenteDatos({
  datos,
  funcionEjecutarSatisfactoria,
}) {
  const contextoAplicacion = useContext(ContextApplication)

  const modalEditar = (datosEditar) => {
    const {id, nombre, version, referencias} = datosEditar

    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <ElegirVersion
            idFuente={id}
            titulo={nombre}
            referencias={referencias.sort(
              (a, b) => a.identifacion - b.identificacion
            )}
            version={version}
            funcionEjecutarSatisfactoria={funcionEjecutarSatisfactoria}
          />
        </>
      ),
      botones: [],
      clasesAdicioneles: 'w-5/12 bg-cendiatra-gris-5',
    })
  }

  const columnas = useMemo(
    () => [
      {
        Header: () => <EncabezadoFondo titulo={'ID'} />,
        accessor: 'id',
        className: 'w-2/12',
        cellStyles:
          'flex justify-center items-center content-center h-14 text-14px text-cendiatra-gris-1',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-l-lg',
        disableFilters: true,
      },
      {
        Header: () => <EncabezadoFondo titulo={'Nombre'} />,
        accessor: 'nombre',
        className: 'w-4/12',
        cellStyles:
          ' flex justify-center items-center content-center h-14 rounded-md text-14px text-cendiatra-gris-1',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
        disableFilters: true,
      },
      {
        Header: () => <EncabezadoFondo titulo={'Última Versión'} />,
        accessor: 'version',
        className: 'w-4/12',
        cellStyles:
          'flex justify-center items-center content-center h-14 rounded-md text-14px text-cendiatra-gris-1',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
        disableFilters: true,
      },
      {
        Header: () => '',
        accessor: 'edit',
        className: 'w-2/12',
        cellStyles:
          'flex justify-center items-center content-center h-14 rounded-md text-14px',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-r-lg',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <div className="flex justify-end items-center content-center">
              <Boton
                titulo="Editar"
                habilitado={true}
                colorPorDefecto={'bg-grayColor'}
                colorActivo={'bg-btnBg'}
                funcionCLick={() => modalEditar(row.original)}
                alto={'h-9'}
              />
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <div className={`w-full ${datos.length ? 'min-h-350' : ''}`}>
      <>
        <TablaConPaginacionYFiltros
          data={datos}
          columns={columnas}
          estilosTablaDatos={`overflow-y-auto ${datos.length ? 'h-350' : ''}`}
        />
      </>
      {!datos.length ? (
        <div className="w-full text-cendiatra justify-center items-center rounded-xl flex flex-wrap p-2 text-xl font-normal mt-5 mb-5">
          No hay datos para mostrar. Por favor, valide los datos de la búsqueda,
          e intente de nuevo.
        </div>
      ) : null}
    </div>
  )
}
