import clsx from 'clsx'
import {useEffect, useRef, useState} from 'react'

const Tooltip = ({children, tooltip, width = 250, activo = false}) => {
  return (
    <div className={clsx('w-full', activo && 'group relative inline-block')}>
      {children}
      {activo ? (
        <span
          className={`absolute top-full left-0 mt-1 z-10 py-2 px-3 rounded-lg border border-cendiatra tooltip ${
            activo ? 'visible opacity-100' : 'invisible opacity-0'
          } bg-cendiatra text-white shadow-lg shadow-slate-600`}
          style={{
            width: width,
          }}
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  )
}

const TextoConTooltip = ({valor}) => {
  const parrafoRef = useRef(null)
  const [esMultiLinea, setEsMultiLinea] = useState(false)
  const [activar, setActivar] = useState(false)

  const verificar = () => {
    const elemento = parrafoRef.current

    if (!elemento) return
    
    const alturaLinea = parseFloat(getComputedStyle(elemento).lineHeight)
    const alturaLineaActual = elemento.offsetHeight

    setEsMultiLinea(alturaLineaActual > alturaLinea)
  }

  useEffect(() => {
    verificar()
  }, [valor])

  useEffect(() => {
    window.addEventListener('resize', verificar)
    return () => window.addEventListener('resize', verificar)
  }, [])

  return (
    <Tooltip tooltip={valor} activo={activar && esMultiLinea} width={'auto'}>
      <p
        ref={parrafoRef}
        className={clsx(
          esMultiLinea && 'line-clamp-1 cursor-pointer',
          'text-left',
          'opacity-80'
        )}
        onMouseOver={() => setActivar(true)}
        onMouseLeave={() => setActivar(false)}
      >
        {valor}
      </p>
    </Tooltip>
  )
}

export default TextoConTooltip
