import React from 'react'
import {CeldaConTooltipPersonalizable} from '../../../Celdas'

const DatosPaciente = ({datos, estilosContenedor = "w-1/4 flex-wrap", estilosTitulo = "text-cendiatra text-xl font-bold mt-5" }) => {
  return (
    <div className="w-full flex  items-center flex-wrap">
      {datos.map((item) => (
        <div key={item.titulo} className={`${estilosContenedor}`}>
          <div className={`${estilosTitulo}`}>
            {item.titulo}
          </div>
          <div className="my-4 text-cendiatra-gris-1 text-xl uppercase line-clamp-1 w-3/4 h-6">
            {item.mostrarToolTip ? (
              <CeldaConTooltipPersonalizable
                align={'justify-start'}
                texto={item.contenido}
              />
            ) : (
              item.contenido
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default DatosPaciente
