import React, {useState} from 'react'
import BotonConImagen from '../../BotonConImagen'
import expandir from '../../../imagenes/Expandible/flechaDesplegableExpandirVerde.svg'
import TablaAtencionesAsociadas from './TablaAtencionesAsociadas'
import {FORMATO_FECHA_GENERAL} from '../../../constantes/formatos'
import moment from 'moment'
import {
  ESTADO_ATENCION_UNIFICACION_DATOS,
  estadoAtencionConstantes,
} from '../../../constantes/estadoAtencion'
import clsx from 'clsx'

const FilaAtencionAsociada = ({datos}) => {
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1)

  const mostrarOEsconderPrecios = (posicion) => {
    setFilaSeleccionada(filaSeleccionada === posicion ? -1 : posicion)
  }

  const retornarEstadoAtencion = (atencion) => {
    return (
      <span
        className={clsx('w-full text-center uppercase font-bold', {
          'text-cendiatra-rojo-1':
            atencion === +estadoAtencionConstantes.CANCELADA,
          'text-cendiatra':
            atencion ===
            +estadoAtencionConstantes['EN PROCESO PENDIENTE POR CERRAR'],
          'text-cendiatra-gris-2':
            atencion !== +estadoAtencionConstantes.CANCELADA &&
            atencion !==
              +estadoAtencionConstantes['EN PROCESO PENDIENTE POR CERRAR'],
        })}
      >
        {ESTADO_ATENCION_UNIFICACION_DATOS[atencion]}
      </span>
    )
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      {datos?.map((atencion, index) => (
        <>
          <div
            key={atencion.atencionId}
            className="w-full flex justify-center items-center border border-cendiatra-verde-2 h-14 rounded-2xl mt-5 relative"
          >
            <div className="w-4/12 flex jusitfy-center items-center text-center text-cendiatra-gris-2">
              <span className="w-6/12 text-center ">
                {atencion.atencionId}{' '}
              </span>
              <span className="w-6/12 text-center ">
                {moment(atencion.fechaAtencion).format(FORMATO_FECHA_GENERAL)}
              </span>
            </div>
            <div className="w-4/12 flex jusitfy-center items-center text-center">
              {retornarEstadoAtencion(atencion.estadoAtencionId)}
            </div>
            <div className="w-4/12 flex jusitfy-center items-center text-center text-cendiatra-gris-2">
              <span className="w-full text-center uppercase">
                {atencion?.empresa?.nombre}
              </span>
            </div>
            {atencion.estadoAtencionId !==
            +estadoAtencionConstantes.CANCELADA ? (
              <div className="absolute right-40 left">
                <BotonConImagen
                  estilosContenedor={'w-full relative left-40 bottom-1'}
                  estilosImagen={'h-9 w-9'}
                  imagen={expandir}
                  medio={true}
                  funcionAEjecutar={() => mostrarOEsconderPrecios(index)}
                  rotacion={filaSeleccionada === index ? '180' : '0'}
                  textoAlternativo={'expandir'}
                />
              </div>
            ) : null}
          </div>
          <TablaAtencionesAsociadas
            datosTabla={atencion.servicioAtenciones}
            desplegarFila={filaSeleccionada === index}
            datosEmpresa={atencion.empresa}
          />
        </>
      ))}
    </div>
  )
}

export default FilaAtencionAsociada
