import React, {useEffect, useState, useContext} from 'react'
import {Input, ListaDesplegable} from '../inputs'
import BotonConImagen from '../BotonConImagen'
import BotonAgregar from '../../imagenes/addButton.png'
import BotonQuitar from '../../imagenes/removeButton.png'
import {OPCIONES_PRECIO, OPCIONES_PRECIO_STRING} from '../../constantes'
import {InputMoneda} from '../inputs'
import {ContextoGestionOtrosServicios} from '../../contexto'
import {aplicarFormatoMoneda} from '../../utilidades'
import {APLICAR_PRECIO_DESDE} from '../../constantes'
import clsx from 'clsx'
import iconoIformacion from '../../imagenes/informacion-fondo-blanco.svg'
import {ImagenEstandar} from '../MostrarInformacion'

const FormularioAgregarRangos = ({
  desplegarFila,
  tieneRangos,
  indice,
  datos,
  setDatos,
}) => {
  const contextoGestionOtrosServicios = useContext(
    ContextoGestionOtrosServicios
  )

  const [infomacionRango, setInformacionRango] = useState({
    concepto: '',
    rangoMinimo: '',
    rangoMaximo: '',
    rangoCompleto: '',
    precioAPartirDe: '',
  })
  const [rangosServicio, setRangosServicio] = useState([
    {
      id: 0,
      concepto: '',
      index: 9999,
      rangoMinimo: 0,
      rangoMaximo: 0,
      precioDiaSiguiente: '',
      precioActual: '',
      rangoCompleto: '? En adelante',
      valor: '',
    },
  ])

  const [todosLosPreciosVacios, setTodosLosPreciosVacios] = useState(true)

  const modificarRango = (nombrePropiedad, valor) => {
    let arreglo = contextoGestionOtrosServicios.datosServicios
    crearArregloServiciosModificados(indice, arreglo, nombrePropiedad, valor)
    setInformacionRango((prev) => {
      return {
        ...prev,
        [nombrePropiedad]: valor ?? '',
      }
    })
    if (nombrePropiedad === 'precioAPartirDe') {
      setRangosServicio((prev) => {
        return prev.map((rango) => {
          return {
            ...rango,
            precioDiaSiguiente: valor,
          }
        })
      })
    }
  }

  useEffect(() => {
    const precarGarRangos = () => {
      if (datos[indice].rangos.length >= 2) {
        setRangosServicio(datos[indice].rangos)
      }
    }

    precarGarRangos()
  }, [contextoGestionOtrosServicios.actualizarRangos])

  useEffect(() => {
    const {rangos} = datos[indice]
    if (rangos?.length) {
      const {precioDiaSiguiente} = rangos[0]
      if (+precioDiaSiguiente === APLICAR_PRECIO_DESDE.A_PARTIR_DE_MANANA) {
        setInformacionRango((prev) => {
          return {
            ...prev,
            precioAPartirDe: APLICAR_PRECIO_DESDE.A_PARTIR_DE_MANANA.toString(),
          }
        })
      }
    }
  }, [datos])

  const agregarRango = () => {
    const posicionEnArray = rangosServicio.length
    const nuevoRango = {
      id: 0,
      index: posicionEnArray,
      concepto: infomacionRango.concepto,
      rangoMinimo: infomacionRango.rangoMinimo,
      rangoMaximo: infomacionRango.rangoMaximo,
      precioDiaSiguiente: infomacionRango.precioAPartirDe,
      precioActual: '',
      rangoCompleto: `${infomacionRango.rangoMinimo} a ${infomacionRango.rangoMaximo} ${infomacionRango.concepto}`,
      valor: '',
    }

    setRangosServicio((prev) => {
      const nuevoArray = [nuevoRango, ...prev]
      const arrayOrganizado = nuevoArray.sort((a, b) => a.index - b.index)
      return arrayOrganizado
    })
    setInformacionRango((prev) => {
      return {
        ...prev,
        rangoMinimo: parseInt(infomacionRango.rangoMaximo) + 1,
        rangoMaximo: '',
        precioAPartirDe: '',
      }
    })
  }

  useEffect(() => {
    const longitudArray = rangosServicio.length
    setRangosServicio((prev) => {
      if (longitudArray === 0) return prev
      const nuevoArray = [...prev]
      const ultimoElemento = {
        ...nuevoArray[nuevoArray.length - 1],
        rangoCompleto:
          longitudArray >= 2
            ? `${
                parseFloat(nuevoArray[nuevoArray.length - 2].rangoMaximo) + 1
              } En adelante`
            : '? En adelante',
        rangoMinimo:
          longitudArray >= 2
            ? parseInt(nuevoArray[nuevoArray.length - 2].rangoMaximo) + 1
            : 0,
      }
      nuevoArray[nuevoArray.length - 1] = ultimoElemento

      return nuevoArray
    })
  }, [rangosServicio.length])

  const modificarValoresServicios = (index, nombrePropiedad, valor) => {
    let arreglo = contextoGestionOtrosServicios.datosServicios
    crearArregloServiciosModificados(indice, arreglo, nombrePropiedad, valor)
    setRangosServicio((prev) => {
      const nuevoArreglo = [...prev]

      if (index >= 0 && index < nuevoArreglo.length) {
        nuevoArreglo[index] = {
          ...nuevoArreglo[index],
          [nombrePropiedad]: valor ?? '',
        }
      }
      return nuevoArreglo
    })
  }
  const verificarPropiedades = (obj) => {
    const requiredProperties = ['concepto', 'rangoMinimo', 'rangoMaximo']

    for (let prop of requiredProperties) {
      if (!obj[prop]) {
        return false
      }
    }

    return true
  }
  const crearArregloServiciosModificados = (
    indice,
    arreglo,
    nombrePropiedad,
    valor
  ) => {
    if (arreglo[indice].id) {
      contextoGestionOtrosServicios.setServiciosModificados((prev) => {
        const index = prev.findIndex((item) => item.id === arreglo[indice].id)
        if (index !== -1) {
          return prev.map((item) =>
            item.id === arreglo[indice].id
              ? {...item, nombre: arreglo[indice].nombre}
              : item
          )
        } else {
          return [
            ...prev,
            {id: arreglo[indice].id, nombre: arreglo[indice].nombre},
          ]
        }
      })
    } else {
      const servicioExistente =
        contextoGestionOtrosServicios.serviciosNuevos.find(
          (servicio) => servicio.indice === indice
        )
      if (servicioExistente) {
        contextoGestionOtrosServicios.setServiciosNuevos((prev) =>
          prev.map((servicio, i) => {
            if (indice === servicio.indice) {
              return {
                ...servicio,
                [nombrePropiedad]: valor,
              }
            }
            return servicio
          })
        )
      } else {
        contextoGestionOtrosServicios.setServiciosNuevos((prev) => [
          ...prev,
          {
            id: '',
            nombre:
              nombrePropiedad === 'nombre' ? valor : arreglo[indice].nombre,
            indice: indice,
          },
        ])
      }
    }
  }
  const manejarRangoEliimnadoArregloServiciosEditados = (indice, arreglo) => {
    if (arreglo[indice].id) {
      contextoGestionOtrosServicios.setServiciosModificados((prev) => {
        const index = prev.findIndex((item) => item.id === arreglo[indice].id)
        if (index !== -1) {
          return prev.map((item) =>
            item.id === arreglo[indice].id
              ? {...item, nombre: arreglo[indice].nombre}
              : item
          )
        } else {
          return [
            ...prev,
            {id: arreglo[indice].id, nombre: arreglo[indice].nombre},
          ]
        }
      })
    }
  }

  const quitarRangoPorIndice = (index) => {
    setRangosServicio((prev) => {
      const nuevoArray = prev.filter((item) => item.index !== index)
      return nuevoArray
    })
    crearArregloServiciosModificados(
      indice,
      contextoGestionOtrosServicios.datosServicios
    )
  }
  useEffect(() => {
    setDatos(
      datos.map((servicio, i) => {
        if (indice === i) {
          return {
            ...servicio,
            rangos: rangosServicio,
          }
        }
        return servicio
      })
    )
    validarPreciosLlenos()
  }, [rangosServicio])

  const validarPreciosLlenos = () => {
    const existenPreciosQueNoEstanVacios = rangosServicio.some(
      (item) => item.valor !== ''
    )
    setTodosLosPreciosVacios(!existenPreciosQueNoEstanVacios)
    if (!existenPreciosQueNoEstanVacios) {
      setInformacionRango((prev) => {
        return {
          ...prev,
          precioAPartirDe: '',
        }
      })
    }
  }

  return (
    <div
      className={`w-full ${
        desplegarFila && tieneRangos ? 'flex' : 'hidden'
      } justify-center items-center border border-cendiatra-verde-2 border-t-0  h-auto rounded-2xl rounded-tr-sm rounded-tl-sm -mt-3 pt-5 flex-wrap`}
    >
      <div className="w-full flex justify-center items-center mt-5 flex-wrap">
        <div className="w-full flex justify-center items-center mb-5">
          <div className="w-3/12 flex justify-center items-center">
            {infomacionRango.precioAPartirDe !== '' ? (
              <span
                className={` ${
                  infomacionRango.precioAPartirDe ===
                  OPCIONES_PRECIO_STRING.INMEDIATO
                    ? 'bg-yellow-200'
                    : 'bg-cendiatra-gris-5 text-cendiatra'
                } w-full  rounded-lg text-center`}
              >
                {infomacionRango.precioAPartirDe ===
                OPCIONES_PRECIO_STRING.INMEDIATO
                  ? '! El precio será asignado de inmediato'
                  : '! El precio será asignado a partir de mañana'}
              </span>
            ) : null}
          </div>
        </div>
        <span className="w-1/12 flex justify-center items-center text-cendiatra-verde-2 mt-4">
          Agregar rango
        </span>
        <div className="w-2/12 flex justify-center items-center text-white font-bold h-full text-start p-1">
          <Input
            onChange={(e) => modificarRango('concepto', e.target.value)}
            estilosContenedor={'w-full'}
            estilosInput={
              !infomacionRango.concepto
                ? 'appearance-none rounded-lg relative block w-full  p-1 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 '
                : 'appearance-none rounded-lg relative block w-full  p-1 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10'
            }
            tipo={'text'}
            placeholder={'DESCRIPCION'}
            titulo={'Concepto para el rango*'}
            valor={infomacionRango.concepto}
          />
        </div>
        <div className="w-1/12 flex justify-center items-center"></div>
        <div className="w-1/12 flex justify-center items-center text-white font-bold h-full text-start p-1">
          <Input
            onChange={(e) => modificarRango('rangoMinimo', e.target.value)}
            estilosContenedor={'w-full'}
            estilosInput={clsx({
              'appearance-none rounded-lg relative block w-full p-1 border border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10':
                !infomacionRango.rangoMinimo && infomacionRango.concepto,
              'appearance-none rounded-lg relative block w-full p-1 border border-cendiatra text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10':
                !(!infomacionRango.rangoMinimo && infomacionRango.concepto),
            })}
            tipo={'number'}
            placeholder={'DESCRIPCION'}
            titulo={'Minimo'}
            valor={infomacionRango.rangoMinimo}
          />
        </div>
        <span className="w-10 flex justify-center items-center text-center text-xl mt-1">
          ___
        </span>
        <div className="w-1/12 flex justify-center items-center text-white font-bold h-full text-start p-1">
          <Input
            onChange={(e) => modificarRango('rangoMaximo', e.target.value)}
            estilosContenedor={'w-full'}
            estilosInput={clsx({
              'appearance-none rounded-lg relative block w-full p-1 border border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10':
                !infomacionRango.rangoMaximo && infomacionRango.concepto,
              'appearance-none rounded-lg relative block w-full p-1 border border-cendiatra text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10':
                !(!infomacionRango.rangoMaximo && infomacionRango.concepto),
            })}
            tipo={'number'}
            placeholder={'DESCRIPCION'}
            titulo={'Maximo'}
            valor={infomacionRango.rangoMaximo}
          />
        </div>

        <div className="w-10">
          <BotonConImagen
            estilosContenedor={'w-full '}
            imagen={BotonAgregar}
            textoAlternativo={'quitar'}
            funcionAEjecutar={agregarRango}
            estilosImagen={'h-7 w-7'}
            desabilitado={!verificarPropiedades(infomacionRango)}
          />
        </div>
        <div className="w-2/12 flex justify-center items-center"></div>
        <div className="w-2/12 flex justify-center items-center text-cendiatra-verde-2 font-bold h-full text-center p-1">
          <ListaDesplegable
            estilosContenedor={'w-full'}
            estilosLista={
              !infomacionRango.precioAPartirDe && !todosLosPreciosVacios
                ? 'appearance-none rounded-xl relative block w-full  p-1 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 '
                : `appearance-none rounded-xl relative block w-full  p-1 border border-cendiatra ${
                    !infomacionRango.precioAPartirDe && todosLosPreciosVacios
                      ? 'text-white bg-cendiatra-gris-2 pointer-events-none'
                      : 'text-cendiatra-gris-3'
                  } text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`
            }
            titulo={'Precio a partir de*'}
            opciones={OPCIONES_PRECIO}
            valor={infomacionRango.precioAPartirDe}
            onChange={(e) => modificarRango('precioAPartirDe', e.target.value)}
          />
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-10 mx-8 mb-20 flex-wrap">
        <div className="w-1/12 flex justify-center items-center text-cendiatra-verde-2 mt-4"></div>
        <div className="w-2/12 flex justify-center items-center text-white font-bold h-full text-start p-1"></div>
        <span className="w-2/12 flex justify-center items-center text-cendiatra font-bold h-full text-start p-1">
          RANGO
        </span>
        <span className="w-10 flex justify-center items-center text-center text-xl mt-1"></span>
        <span className="w-10% flex justify-center items-center text-cendiatra font-bold h-full text-start p-1">
          PRECIO ACTUAL
        </span>
        <span className="w-10 flex justify-center items-center text-center text-xl mt-1"></span>
        <span className="w-2/12 flex justify-center items-center text-cendiatra font-bold h-full text-start p-1">
          PRECIO NUEVO
        </span>
        <div className="w-10 flex justify-center items-center"></div>
        <div className="w-12 flex justify-center items-center"></div>
        <div className="w-1/12 flex justify-center items-center"></div>
        <span className="w-full border-b-2"></span>
        {rangosServicio.map((rango, index) => (
          <div className="w-full flex justify-center items-center my-2 flex-wrap">
            <div className="w-1/12 flex justify-center items-center text-cendiatra-verde-2 mt-4"></div>
            <div className="w-2/12 flex justify-center items-center text-cendiatra-gris-3 font-bold h-full text-start p-1">
              {!rango.id && rango.valor ? (
                <span className="w-36 bg-yellow-200 rounded text-center">
                  ! Nuevo rango
                </span>
              ) : null}
            </div>
            <span className="w-2/12 flex justify-center items-center text-cendiatra font-bold h-full text-start p-1">
              {rango.rangoCompleto}
            </span>
            <span className="w-10 flex justify-center items-center text-center text-xl mt-1"></span>
            <span className="w-10% flex justify-center items-center text-cendiatra-gris-2 font-bold h-full text-start p-1">
              {rango.precioActual && aplicarFormatoMoneda(rango.precioActual)}
            </span>
            <span className="w-10 flex justify-center items-center text-center text-xl mt-1"></span>
            <div className="w-2/12 flex justify-center items-center text-cendiatra font-bold h-full text-start p-1">
              <InputMoneda
                estilosContenedor={'w-full'}
                estilosInput={
                  !rango.valor
                    ? `appearance-none rounded-xl relative block w-full  p-1 border ${
                        rango.index === 9999 && rangosServicio.length < 2
                          ? 'border-cendiatra bg-cendiatra-gris-2 pointer-events-none placeholder-white'
                          : 'border-cendiatra-rojo-1'
                      }  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10`
                    : `appearance-none rounded-xl relative block w-full  p-1 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`
                }
                onValueChange={(e) =>
                  modificarValoresServicios(index, 'valor', e.floatValue)
                }
                placeholder={'$0,00'}
                formatoVacio={true}
                valor={rango.valor}
              />
            </div>

            <div className="w-10 flex justify-center items-center">
              <ImagenEstandar
                estilosContenedor={clsx(
                  'w-full',
                  rango.valor &&
                    parseInt(infomacionRango.precioAPartirDe) !==
                      APLICAR_PRECIO_DESDE.A_PARTIR_DE_MANANA
                    ? 'flex'
                    : 'hidden',
                  'justify-center',
                  'items-center'
                )}
                imagen={iconoIformacion}
                estilosImagen={'w-6 h-6'}
                textoAlternativo={
                  'imagen indicativa el nuevo precio fue ingresado'
                }
              />
            </div>
            <div className="w-12 flex justify-center items-center">
              {rango.index !== 9999 ? (
                <BotonConImagen
                  estilosContenedor={'w-full -mt-5'}
                  imagen={BotonQuitar}
                  textoAlternativo={'quitar'}
                  funcionAEjecutar={() => quitarRangoPorIndice(rango.index)}
                  estilosImagen={'h-7 w-7'}
                />
              ) : null}
            </div>
            <div className="w-1/12 flex justify-center items-center flex-wrap text-cendiatra text-center">
              {rango.precioDiaSiguiente ===
                OPCIONES_PRECIO_STRING.A_PARTIR_DE_MAÑANA && rango.valor ? (
                <>
                  <span className="w-full">A PARTIR</span>
                  <span className="w-full">DE MAÑANA</span>
                </>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FormularioAgregarRangos
