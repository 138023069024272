import React, {useState} from 'react'
import {Input} from '../../inputs'
import BotonConImagen from '../../BotonConImagen'
import addButtonImg from '../../../imagenes/addButton.png'
import ListaOpciones from './ListaOpciones'

const AgregarOpciones = ({
  opciones,
  setOpciones,
  esEdicion = false,
  setCambios,
  opcionesIniciales = [],
}) => {
  const [nuevoOpcion, setNuevoOpcion] = useState('')
  const [error, setError] = useState('')

  const agregarOpcion = () => {
    if (nuevoOpcion.trim() === '') {
      setError('La opción no puede estar vacía')
      return
    }

    if (opciones.some((opcion) => opcion.valor === nuevoOpcion)) {
      setError('La opción ya existe')
      return
    }

    setOpciones([...opciones, {id: Date.now(), valor: nuevoOpcion}])
    setNuevoOpcion('')
    setError('')
  }

  const eliminarOpcion = (id) => {
    if (esEdicion) {
      const noEsNuevoElemento = opcionesIniciales.find(
        (elemento) => elemento.id === id
      )
      if (!noEsNuevoElemento) {
        setCambios((prev) => ({
          ...prev,
          nuevos: prev.nuevos.filter((item) => item.id !== id),
        }))
      } else {
        const elementoEliminado = opciones.find((opcion) => opcion.id === id)
        setCambios((prev) => ({
          ...prev,
          eliminados: [...prev.eliminados, elementoEliminado],
        }))
      }
    }
    const nuevoArreglo = opciones.filter((opcion) => opcion.id !== id)
    setOpciones(nuevoArreglo)
  }

  const actualizarOpcion = (id, nuevoValor) => {
    setOpciones(
      opciones.map((opcion) =>
        opcion.id === id ? {...opcion, valor: nuevoValor} : opcion
      )
    )
    const noEsNuevoElemento = opcionesIniciales.find(
      (elemento) => elemento.id === id
    )
    if (esEdicion && noEsNuevoElemento) {
      const existeElemento = opcionesIniciales.find(
        (elemento) => elemento.valor === nuevoValor
      )
      if (existeElemento) {
        eliminarElementoModificado(id)
      } else {
        actualizarElementosModificados(id)
      }
    }
  }
  const eliminarElementoModificado = (id) => {
    setCambios((prev) => ({
      ...prev,
      modificados: prev.modificados.filter((item) => item.id !== id),
    }))
  }

  const actualizarElementosModificados = (id) => {
    setCambios((prev) => {
      const elementoModificado = opciones.find((opcion) => opcion.id === id)
      const modificadosActualizados = prev.modificados.some(
        (item) => item.id === elementoModificado.id
      )
        ? prev.modificados.map((item) =>
            item.id === elementoModificado.id ? elementoModificado : item
          )
        : [...prev.modificados, elementoModificado]

      return {
        ...prev,
        modificados: modificadosActualizados,
      }
    })
  }

  const agregarOpcionDesdeLista = (index) => {
    const nuevaOpcion = {id: Date.now(), valor: ''}
    const nuevasOpciones = [...opciones]
    nuevasOpciones.splice(index + 1, 0, nuevaOpcion)
    setOpciones(nuevasOpciones)

    if (esEdicion) {
      setCambios((prev) => ({
        ...prev,
        nuevos: [...prev.nuevos, nuevaOpcion],
      }))
    }
  }

  return (
    <div className="w-full bg-white mt-5 rounded-xl shadow-2xl pb-5">
      {esEdicion ? (
        <div className="w-full bg-cendiatra-verde-7 h-14 flex justify-center items-center rounded-lg px-1 py-1.5">
          <span className="w-full flex justify-center text-lg items-center bg-cendiatra-verde-5 text-white h-full rounded-lg normal-case text-center">
            Lista de opciones
          </span>
        </div>
      ) : (
        <div className="flex justify-center items-center gap-3 p-5">
          <span className="text-cendiatra-gris-1">OPCIONES</span>
          <Input
            estilosContenedor={'w-full'}
            estilosInput={
              'appearance-none rounded relative block w-full text-13px p-1.5 border text-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 focus:border-cendiatra border-cendiatra'
            }
            tipo={'text'}
            placeholder={'DESCRIPCIÓN'}
            titulo={''}
            valor={nuevoOpcion}
            onChange={(e) => {
              setNuevoOpcion(e.target.value)
              setError('')
            }}
          />

          <BotonConImagen
            estilosContenedor={'w-8 pt-2'}
            imagen={addButtonImg}
            medio={true}
            funcionAEjecutar={agregarOpcion}
            textoAlternativo={'agregar'}
          />
        </div>
      )}

      {error && <p className="text-red-500 mt-2">{error}</p>}

      <div className=" h-350 overflow-auto px-5">
        {opciones.map((opcion, index) => (
          <ListaOpciones
            key={opcion.id}
            opcion={opcion}
            onRemove={eliminarOpcion}
            onUpdate={actualizarOpcion}
            onAdd={agregarOpcionDesdeLista}
            index={index}
            esEdicion={true}
            todasLasOpciones={opciones}
          />
        ))}
      </div>
    </div>
  )
}

export default AgregarOpciones
