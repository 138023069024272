import React, {useEffect, useMemo, useState} from 'react'
import {Boton, BotonOpcion} from '../../Botones'
import {
  Formula,
  ListaDesplegablePropiedades,
  Numerico,
  Rango,
  TextoCorto,
  TextoLargo,
} from './Campos.jsx'

const EditarPropiedades = ({campo, funcionGuardar, funcionRegresar}) => {
  const [requerido, setRequerido] = useState(campo.requerido)
  const [mostrarEnCertificado, setMostrarEnCertificado] = useState(
    campo.mostrarEnCertificado
  )
  const [atributos, setAtributos] = useState(campo.atributos)
  const [replicable, setReplicable] = useState(campo.replicable ?? false)

  const actualizarAtributo = (nombreAtributo, valorAtributo) => {
    setAtributos({...atributos, [nombreAtributo]: valorAtributo})
  }

  const actualizarAtributos = (listaAtributos) => {
    let atributosActualizados = {...atributos}
    listaAtributos.forEach((atributo) => {
      atributosActualizados = {
        ...atributosActualizados,
        [atributo.nombreAtributo]: atributo.valorAtributo,
      }
    })
    setAtributos(atributosActualizados)
  }

  useEffect(() => {
    setAtributos(campo.atributos)
  }, [campo])

  const componenteCampo = useMemo(() => {
    if (!campo) {
      return null
    }

    const componentes = {
      1: (
        <TextoCorto
          atributos={atributos}
          actualizarAtributo={actualizarAtributo}
        />
      ),
      2: (
        <TextoLargo
          atributos={atributos}
          actualizarAtributo={actualizarAtributo}
        />
      ),
      3: (
        <ListaDesplegablePropiedades
          nombreCampo={campo.tituloCampo}
          atributos={atributos}
          actualizarAtributo={actualizarAtributo}
          actualizarAtributos={actualizarAtributos}
        />
      ),
      4: (
        <ListaDesplegablePropiedades
          nombreCampo={campo.tituloCampo}
          atributos={atributos}
          actualizarAtributo={actualizarAtributo}
          actualizarAtributos={actualizarAtributos}
        />
      ),
      8: (
        <Numerico
          atributos={atributos}
          actualizarAtributo={actualizarAtributo}
        />
      ),
      9: (
        <Formula
          atributos={atributos}
          actualizarAtributo={actualizarAtributo}
        />
      ),
      10: (
        <Rango atributos={atributos} actualizarAtributo={actualizarAtributo} />
      ),
    }

    const componente = componentes[Number(campo?.tipoCampoId ?? 0)]
    if (componente) return componente
    else return null
  }, [campo, atributos, actualizarAtributo, actualizarAtributos])

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full flex flex-wrap justify-center items-center">
        <BotonOpcion
          id={1}
          titulo={'¿ES CAMPO OBLIGATORIO?'}
          estaActivo={requerido}
          esHorizontal={true}
          funcionClick={() => {
            setRequerido(!requerido)
          }}
        />
        <BotonOpcion
          id={2}
          titulo={'¿MOSTRAR EN EL CERTIFICADO?'}
          estaActivo={mostrarEnCertificado}
          esHorizontal={true}
          funcionClick={() => {
            setMostrarEnCertificado(!mostrarEnCertificado)
          }}
        />
        <BotonOpcion
          id={2}
          titulo={'¿ES REPLICABLE EL CAMPO?'}
          estaActivo={replicable}
          esHorizontal={true}
          funcionClick={() => {
            setReplicable((prv) => !prv)
          }}
        />
        {componenteCampo}
        <Boton
          titulo="Regresar"
          lineaPrimerCaracter={false}
          habilitado={true}
          funcionCLick={() => {
            funcionRegresar()
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          tamanoTexto={'text-sm'}
          ancho={'w-2/12'}
          alto={'h-10 my-10'}
        />
        <Boton
          titulo="Guardar"
          lineaPrimerCaracter={false}
          habilitado={true}
          funcionCLick={() => {
            funcionGuardar(
              requerido,
              mostrarEnCertificado,
              replicable,
              atributos
            )
          }}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          tamanoTexto={'text-sm'}
          ancho={'w-2/12'}
          alto={'h-10 my-10'}
        />
      </div>
    </div>
  )
}

export default EditarPropiedades
