import React, {useContext, useEffect, useState} from 'react'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {LISTADO_GENEROS, LISTADO_ZONAS, TEXTO_VISUAL} from '../../../constantes'
import {FiltroUnificarAtenciones} from '../../../componentes/inputs'
import {ContenedorTituloResaltado} from '../../../componentes/Contenedores'
import {BarraEncabezadosPestana} from '../../../componentes/Pestanas'
import {ContextoUnificacionDeDatos, ContextApplication} from '../../../contexto'
import Pestana from '../../../componentes/Pestanas/Pestana'
import {
  UnificacionInformacionPaciente,
  UnificacionAtencionesAsociadas,
  SeleccionarDatosCorrectos,
  RevisarYUnificarDatos,
} from '../../../componentes/jefeDeSede/UnificacionDeDatos'
import {useModal} from '../../../hooks/useModal'
import moment from 'moment'
import {FORMATO_FECHA_GENERAL} from '../../../constantes'
import {ABREVIACION_ID_DOCUMENTO} from '../../../constantes/tipoDocumentos'
import {ObtenerGrupoSanguineoPorId} from '../../../cache/servicios-cache/GrupoSanguineo'
import {
  ObtenerEstadoCivilPorId,
  ObtenerEscolaridadPorId,
  ObtenerDepartamentoPorId,
  ObtenerInformacionCiudadPorId,
  ObtenerLocalidadPorId,
} from '../../../cache/servicios-cache'
import {
  BusquedaDocumentosParaUnificar,
  UnificarInformacionUsuario,
} from '../../../microservicios'
import {useHistory} from 'react-router-dom'

const UnificarDatosPaciente = () => {
  const listaPestanas = [
    'Información del Paciente',
    'Atenciones asociadas',
    'Seleccionar datos correctos',
    'Revisar y unificar datos',
  ]
  const contextoUnificacionDeDatos = useContext(ContextoUnificacionDeDatos)

  const contextoAplicacion = useContext(ContextApplication)

  const [cerrarModal] = useModal()

  const [existenDatos, setExistenDatos] = useState(false)

  const history = useHistory()

  const finalizarTransaccion = () => {
    history.go(0)
    cerrarModal()
  }

  const crearResumenDatos = (datos) => {
    const resumenDatos = {
      urlFoto: datos?.urlFoto,
      datos: [
        {
          titulo: 'ID',
          contenido: datos.id,
          estilos: 'w-2/12',
        },
        {
          titulo: 'N° de Documento',
          contenido: datos.numeroDocumento,
          estilos: 'w-3/12',
        },
        {
          titulo: 'Nombre y Apellido',
          contenido: `${datos.nombres} ${datos.apellidos}`,
          estilos: 'w-4/12',
        },
        {
          titulo: 'Fecha de última atención',
          contenido: moment(datos.fechaUltimaAtencion)?.format(
            FORMATO_FECHA_GENERAL
          ),
          estilos: 'w-3/12',
        },
      ],
    }
    return resumenDatos
  }

  const crearInformacionParaMostrar = async (datos, valorSeleccionado) => {
    const datosUsuario = []
    const datosDireccion = []

    datosUsuario.push({
      titulo: 'No. Documento*',
      contenido: datos?.usuario?.numeroDocumento ?? '',
      seleccionado: valorSeleccionado,
      id: 1,
    })

    datosUsuario.push({
      titulo: 'Tipo de documento*',
      contenido: !!datos?.usuario?.tipoDocumento
        ? ABREVIACION_ID_DOCUMENTO[datos.usuario.tipoDocumento]
        : '',
      datoInicial: datos?.usuario?.tipoDocumento ?? '',
      seleccionado: valorSeleccionado,
      id: 2,
    })

    datosUsuario.push({
      titulo: 'Apellidos*',
      contenido: datos?.usuario?.apellidos ?? '',
      seleccionado: valorSeleccionado,
      id: 3,
    })

    datosUsuario.push({
      titulo: 'Nombre*',
      contenido: datos?.usuario?.nombres ?? '',
      seleccionado: valorSeleccionado,
      id: 4,
    })

    datosUsuario.push({
      titulo: 'Grupo Sanguineo',
      contenido: !!datos?.usuario?.grupoSanguineoId
        ? (await ObtenerGrupoSanguineoPorId(datos.usuario.grupoSanguineoId))
            ?.Nombre
        : '',
      datoInicial: datos?.usuario?.grupoSanguineoId ?? '',
      seleccionado: valorSeleccionado,
      id: 5,
    })

    datosUsuario.push({
      titulo: 'Fecha de nacimiento*',
      contenido: moment(datos.usuario.fechaNacimiento).isValid()
        ? moment(datos.usuario.fechaNacimiento).format(FORMATO_FECHA_GENERAL)
        : '',
      datoInicial: datos?.usuario?.fechaNacimiento ?? '',
      seleccionado: valorSeleccionado,
      id: 6,
    })

    datosUsuario.push({
      titulo: 'Sexo*',
      contenido: !!datos?.usuario?.sexoId
        ? LISTADO_GENEROS[datos.usuario.sexoId]
        : '',
      datoInicial: datos?.usuario?.sexoId ?? '',
      seleccionado: valorSeleccionado,
      id: 7,
    })

    datosUsuario.push({
      titulo: 'Estado Civil*',
      contenido: !!datos?.usuario?.estadoCivilId
        ? (await ObtenerEstadoCivilPorId(datos.usuario.estadoCivilId))?.Nombre
        : '',
      datoInicial: datos?.usuario?.estadoCivilId ?? '',
      seleccionado: valorSeleccionado,
      id: 8,
    })

    datosUsuario.push({
      titulo: 'Lugar de nacimiento',
      contenido: datos?.usuario?.lugarNacimiento ?? '',
      seleccionado: valorSeleccionado,
      mostrarToolTip: true,
      id: 9,
    })

    datosUsuario.push({
      titulo: 'Escolaridad*',
      contenido: !!datos?.usuario?.escolaridadId
        ? (await ObtenerEscolaridadPorId(datos.usuario.escolaridadId))?.Nombre
        : '',
      datoInicial: datos?.usuario?.escolaridadId ?? '',
      seleccionado: valorSeleccionado,
      id: 10,
    })

    datosUsuario.push({
      titulo: 'Nacionalidad',
      contenido: datos?.usuario?.nacionalidad ?? '',
      seleccionado: valorSeleccionado,
      id: 11,
    })

    datosDireccion.push({
      titulo: 'Pais de residencia*',
      contenido: 'COLOMBIA',
      seleccionado: valorSeleccionado,
      id: 1,
    })

    datosDireccion.push({
      titulo: 'Departamento*',
      contenido: !!datos?.direccionUsuario?.departamentoId
        ? (
            await ObtenerDepartamentoPorId(
              datos.direccionUsuario.departamentoId
            )
          )?.Nombre
        : '',
      datoInicial: datos?.direccionUsuario?.departamentoId ?? '',
      seleccionado: valorSeleccionado,
      id: 2,
    })

    datosDireccion.push({
      titulo: 'Municipio*',
      contenido: !!datos?.direccionUsuario?.municipioId
        ? (
            await ObtenerInformacionCiudadPorId(
              datos.direccionUsuario.municipioId
            )
          )?.Nombre
        : '',
      datoInicial: datos?.direccionUsuario?.municipioId ?? '',
      seleccionado: valorSeleccionado,
      id: 3,
    })

    datosDireccion.push({
      titulo: 'Localidad',
      contenido: !!datos?.direccionUsuario?.localidadId
        ? (await ObtenerLocalidadPorId(datos.direccionUsuario.localidadId))
            ?.Nombre
        : '',
      datoInicial: datos?.direccionUsuario?.localidadId ?? '',
      seleccionado: valorSeleccionado,
      id: 4,
    })

    datosDireccion.push({
      titulo: 'Zona',
      contenido: !!datos?.direccionUsuario?.zonaId
        ? LISTADO_ZONAS[datos.direccionUsuario.zonaId]
        : '',
      datoInicial: datos?.direccionUsuario?.zonaId ?? '',
      seleccionado: valorSeleccionado,
      id: 5,
    })

    datosDireccion.push({
      titulo: 'Dirección',
      contenido: datos?.direccionUsuario?.direccion ?? '',
      seleccionado: valorSeleccionado,
      id: 6,
    })

    datosDireccion.push({
      titulo: 'Estrato*',
      contenido: !!datos?.direccionUsuario?.estratoId
        ? datos.direccionUsuario.estratoId
        : '',
      seleccionado: valorSeleccionado,
      id: 7,
    })

    datosDireccion.push({
      titulo: 'Telefono*',
      contenido: datos?.direccionUsuario?.telefono ?? '',
      seleccionado: valorSeleccionado,
      id: 8,
    })

    datosDireccion.push({
      titulo: 'Celular*',
      contenido: !!datos?.direccionUsuario?.celular
        ? `${datos.direccionUsuario?.indicativo ?? ''} ${
            datos.direccionUsuario.celular
          }`.trim()
        : '',
      datoInicial: {
        indicativo: datos?.direccionUsuario?.indicativo ?? '',
        celular: datos?.direccionUsuario?.celular ?? '',
      },
      seleccionado: valorSeleccionado,
      id: 9,
    })

    datosDireccion.push({
      titulo: 'Correo electrónico*',
      contenido: datos?.direccionUsuario?.correo ?? '',
      seleccionado: valorSeleccionado,
      id: 10,
    })

    return {datosUsuario, datosDireccion}
  }

  const obetenerDatosIniciales = async (datosUsuariosUnificacion) => {
    const {
      numeroDocumentoCorrecto,
      tipoDeDocumentoCorrecto,
      numeroDocumentoIncorrecto,
      tipoDeDocumentoIncorrecto,
    } = datosUsuariosUnificacion

    const payloadUsuarios = {
      correcto: {
        tipoDocumento: tipoDeDocumentoCorrecto,
        numeroDocumento: numeroDocumentoCorrecto,
      },
      incorrecto: {
        tipoDocumento: tipoDeDocumentoIncorrecto,
        numeroDocumento: numeroDocumentoIncorrecto,
      },
    }

    const response = await BusquedaDocumentosParaUnificar(payloadUsuarios)
    try {
      if (response.status === 200) {
        const resumenDatosCorrectos = crearResumenDatos(
          response?.data?.correcto?.usuario
        )

        const resumenDatosIncorrectos = crearResumenDatos(
          response?.data?.incorrecto?.usuario
        )

        contextoUnificacionDeDatos.setResumenDatos({
          correcto: resumenDatosCorrectos,
          incorrecto: resumenDatosIncorrectos,
        })

        const datosParaMostrarCorrectos = await crearInformacionParaMostrar(
          response?.data?.correcto,
          true
        )

        const datosParaMostrarIncorrectos = await crearInformacionParaMostrar(
          response?.data?.incorrecto,
          false
        )

        contextoUnificacionDeDatos.setDatosParaMostrar({
          correcto: datosParaMostrarCorrectos,
          idUsuarioCorrecto: response?.data?.correcto.usuario.id,
          incorrecto: datosParaMostrarIncorrectos,
          idUsuarioIncorrecto: response?.data?.incorrecto.usuario.id,
        })
        setExistenDatos(true)
      }
    } catch (error) {
      console.log(error)
      setExistenDatos(true)
    }
  }

  useEffect(() => {
    contextoUnificacionDeDatos.setNumeroPagina(1)
    contextoUnificacionDeDatos.setTotalPaginas(4)
  }, [])

  const unificarDatosPaciente = async () => {
    const payload = {
      usuarioIdCorrecto:
        contextoUnificacionDeDatos?.datosParaMostrar?.idUsuarioCorrecto,
      usuarioIdIncorrecto:
        contextoUnificacionDeDatos?.datosParaMostrar?.idUsuarioIncorrecto,
      infPersonal: {
        tipoDocumento:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Tipodedocumento,
        numeroDocumento:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.NoDocumento,
        nombres:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Nombre,
        apellidos:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Apellidos,
        grupoSanguineoId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.GrupoSanguineo,
        fechaNacimiento:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Fechadenacimiento,
        sexoId: contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Sexo,
        estadoCivilId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.EstadoCivil,
        lugarNacimiento:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Lugardenacimiento,
        escolaridadId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Escolaridad,
        nacionalidad:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Nacionalidad,
      },
      infDireccion: {
        paisId: 1,
        departamentoId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Departamento,
        municipioId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Municipio,
        localidadId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Localidad,
        zonaId: contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Zona,
        direccion:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Dirección,
        estratoId:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Estrato,
        telefono:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Telefono,
        celular:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Celular
            ?.celular,
        indicativo:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload?.Celular
            ?.indicativo,
        correo:
          contextoUnificacionDeDatos?.datosUnificados?.datosPayload
            ?.Correoelectrónico,
      },
    }
    try {
      const response = await UnificarInformacionUsuario(payload)
      if (response.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          contenido: (
            <div className="w-full flex justify-cneter items-center flex-wrap ">
              <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5 normal-case text-3xl">
                Se unificó los datos del paciente correctamente
              </span>

              {contextoUnificacionDeDatos.resumenDatos.correcto.datos.map(
                (item) => (
                  <div
                    key={item.titulo}
                    className={`${item.estilos} flex-wrap mt-10`}
                  >
                    <div className={'text-cendiatra text-sm mt-5'}>
                      {item.titulo}
                    </div>
                    <div className="my-4 text-cendiatra-gris-1 text-sm  w-full h-6">
                      {item.contenido}
                    </div>
                  </div>
                )
              )}
            </div>
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: () => finalizarTransaccion(),
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const confirmarDatos = () => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="w-full flex justify-cneter items-center flex-wrap ">
          <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5 normal-case text-3xl">
            Se unificará los datos del paciente
          </span>

          {contextoUnificacionDeDatos.resumenDatos.correcto.datos
            .filter(
              (elemento) =>
                !elemento.titulo.includes('Fecha de última atención')
            )
            .map((item) => (
              <div key={item.titulo} className={`w-4/12 flex-wrap mt-10`}>
                <div className={'text-cendiatra text-lg mt-5'}>
                  {item.titulo}
                </div>
                <div className="my-4 text-cendiatra-gris-1 text-lg  w-full h-6">
                  {item.contenido}
                </div>
              </div>
            ))}
          <span className="w-full flex justify-center items-center text-cendiatra mt-10 normal-case">
            ¿Desea unificar los registros?
          </span>
          <span className="w-full flex justify-center items-center text-cendiatra-rojo-1 mt-8 normal-case">
            Recuerde que esta acción no se puede deshacer, por favor, verifique
            antes de continuar.
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: cerrarModal,
        },
        {
          nombre: 'Continuar',
          click: unificarDatosPaciente,
        },
      ],
    })
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULO_JEFE_DE_SEDE.UNIFICACION_DATOS_PACIENTE}
      estilosContenedor={'w-95%'}
    >
      <span className="w-full flex justify-center items-center text-cendiatra mb-10 text-lg">
        Digite el número de documento para buscar el paciente duplicado.
      </span>
      <FiltroUnificarAtenciones funcionSubmit={obetenerDatosIniciales} />
      <span className="w-full flex justify-center items-center text-cendiatra mb-1 mt-10 text-lg">
        Seleccione los registros a unificar y designe uno como principal.
      </span>
      <span className="w-full flex justify-center items-center text-cendiatra-rojo-1 mb-10 text-lg">
        Se tomarán los datos del registro principal como registro correcto y
        verdadero.
      </span>
      <ContenedorTituloResaltado
        paddingContenedor="py-3 px-4"
        titulo={'Paciente encontrado'}
      >
        {existenDatos ? (
          <div className="w-full flex items-center justify-center flex-wrap">
            <BarraEncabezadosPestana
              titulos={listaPestanas}
              contexto={contextoUnificacionDeDatos}
              deshabilitado={null}
            />
            <div className="w-full flex justify-evenly items-center flex-wrap">
              <span className="w-full flex justify-center items-center text-cendiatra mt-5">
                {contextoUnificacionDeDatos.numeroPagina === 2
                  ? 'Últimas 10 atenciones'
                  : 'Detalles del registro'}
              </span>
              {contextoUnificacionDeDatos.numeroPagina !== 4 ? (
                <>
                  <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
                    <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
                      Correcto
                    </span>
                    <div className="w-full flex justify-center items-center">
                      <img
                        className="w-24 h-24"
                        src={
                          contextoUnificacionDeDatos?.resumenDatos?.correcto
                            ?.urlFoto
                        }
                        alt=""
                      />
                    </div>
                    {contextoUnificacionDeDatos?.resumenDatos?.correcto?.datos.map(
                      (item) => (
                        <div
                          key={item.titulo}
                          className={`${item.estilos} flex-wrap`}
                        >
                          <div className={'text-cendiatra text-sm mt-5'}>
                            {item.titulo}
                          </div>
                          <div className="my-4 text-cendiatra-gris-1 text-sm  w-full h-6 uppercase">
                            {item.contenido}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
                    <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
                      Incorrecto
                    </span>
                    <div className="w-full flex justify-center items-center">
                      <img
                        className="w-24 h-24"
                        src={
                          contextoUnificacionDeDatos?.resumenDatos?.incorrecto
                            ?.urlFoto
                        }
                        alt=""
                      />
                    </div>
                    {contextoUnificacionDeDatos?.resumenDatos?.incorrecto?.datos.map(
                      (item) => (
                        <div
                          key={item.titulo}
                          className={`${item.estilos} flex-wrap`}
                        >
                          <div className={'text-cendiatra text-sm mt-5'}>
                            {item.titulo}
                          </div>
                          <div className="my-4 text-cendiatra-gris-1 text-sm  w-full h-6 uppercase">
                            {item.contenido}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : (
                <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
                  <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
                    Correcto
                  </span>
                  <div className="w-full flex justify-center items-center">
                    <img
                      className="w-24 h-24"
                      src={
                        contextoUnificacionDeDatos?.resumenDatos?.correcto
                          ?.urlFoto
                      }
                      alt="fotoUsuario"
                    />
                  </div>
                  {contextoUnificacionDeDatos?.resumenDatos?.correcto?.datos.map(
                    (item) => (
                      <div
                        key={item.titulo}
                        className={`${item.estilos} flex-wrap`}
                      >
                        <div className={'text-cendiatra text-sm mt-5'}>
                          {item.titulo}
                        </div>
                        <div className="my-4 text-cendiatra-gris-1 text-sm  w-full h-6">
                          {item.contenido}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            <Pestana contexto={contextoUnificacionDeDatos} numeroPagina={1}>
              <UnificacionInformacionPaciente
                datos={contextoUnificacionDeDatos?.datosParaMostrar}
              />
            </Pestana>
            <Pestana contexto={contextoUnificacionDeDatos} numeroPagina={2}>
              <UnificacionAtencionesAsociadas numeroPagina={2} />
            </Pestana>
            <Pestana contexto={contextoUnificacionDeDatos} numeroPagina={3}>
              <SeleccionarDatosCorrectos
                datos={contextoUnificacionDeDatos?.datosParaMostrar}
              />
            </Pestana>
            <Pestana
              contexto={contextoUnificacionDeDatos}
              numeroPagina={4}
              textoBotonUltimaPagina={'Guardar'}
              clickGuardar={() => confirmarDatos()}
              esModificacionAtencion={true}
            >
              <RevisarYUnificarDatos numeroPagina={4} />
            </Pestana>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center">
            <span className="w-auto text-cendiatra text-lg mt-5">
              No hay datos para mostrar. Por favor, cambie los datos de la
              búsqueda, e intente de nuevo.
            </span>
          </div>
        )}
      </ContenedorTituloResaltado>
    </ContenedorPagina>
  )
}

export default UnificarDatosPaciente
