import React from 'react'
import DatosPaciente from '../../recepcionista/formularioDeRegistro/confirmarDatos/DatosPaciente'

const UnificacionInformacionPaciente = ({datos}) => {
  return (
    <div className="w-full flex justify-evenly items-center flex-wrap mb-20">
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Personal
        </span>
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5">
          <DatosPaciente
            datos={datos?.correcto?.datosUsuario}
            estilosContenedor={'w-1/2 flex-wrap'}
            estilosTitulo={'text-cendiatra text-xl mt-5'}
          />
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Personal
        </span>
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5">
          <DatosPaciente
            datos={datos?.incorrecto?.datosUsuario}
            estilosContenedor={'w-1/2 flex-wrap'}
            estilosTitulo={'text-cendiatra text-xl mt-5'}
          />
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Dirección
        </span>
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5">
          <DatosPaciente
            datos={datos?.correcto?.datosDireccion}
            estilosContenedor={'w-1/2 flex-wrap'}
            estilosTitulo={'text-cendiatra text-xl mt-5'}
          />
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Dirección
        </span>
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5">
          <DatosPaciente
            datos={datos?.incorrecto?.datosDireccion}
            estilosContenedor={'w-1/2 flex-wrap'}
            estilosTitulo={'text-cendiatra text-xl mt-5'}
          />
        </div>
      </div>
    </div>
  )
}

export default UnificacionInformacionPaciente
