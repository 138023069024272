import React from 'react'

const EncabezadoDinamico = ({datos}) => {
  return (
    <div className="w-full flex justify-center items-center bg-cendiatra-verde-2 h-14 rounded-2xl flex-wrap mt-5">
      {datos.map((encabezado) => (
        <div
          key={encabezado.titulo}
          className={`${encabezado.estilos} flex justify-center items-center text-white font-bold h-full text-center`}
        >
          {encabezado.titulo}
        </div>
      ))}
    </div>
  )
}

export default EncabezadoDinamico
