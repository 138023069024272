import {dbComercial} from '../bases-datos/dbComercial'
import Dexie from 'dexie'
import {ObtenerFuenteDatosPlantillas} from '../../microservicios/Referencia'

export const AgregarFuentesDeDatosACache = async () => {
  await dbComercial.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbComercial
        .table('FechasActualizacion')
        .get({Tabla: 'FuentesDeDatos'})
    )?.FechaUltimaActualizacion
    await ObtenerFuenteDatosPlantillas(fechaUltimaActualizacion).then((res) => {
      dbComercial.FuentesDeDatos.bulkPut(
        res.data.map((fuenteDatos, posicion) => ({
          Id: posicion + 1,
          Nombre: fuenteDatos.nombre,
          Opciones: fuenteDatos.opciones,
          NumeroVersiones: fuenteDatos?.versiones?.length ?? 1,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar fuentes de datos al caché comercial: ' +
            (e.stack || e)
        )
      })
      dbComercial.FechasActualizacion.put({
        Tabla: 'FuentesDeDatos',
        FechaUltimaActualizacion: res.headers.tiemposervidor,
      })
    })
  })
}

export const ObtenerFuenteDeDatosPorId = async (id) => {
  return Promise.resolve(
    dbComercial.table('FuentesDeDatos').get({Id: parseInt(id)})
  )
}

export const ObtenerFuenteDeDatosPorNombre = async (nombre) => {
  if (!nombre) {
    return null
  }
  return Promise.resolve(
    dbComercial.table('FuentesDeDatos').get({Nombre: nombre})
  )
}

export const ObtenerFuentesDeDatos = async () => {
  return Promise.resolve(dbComercial.table('FuentesDeDatos').toArray())
}

export const ObtenerAutocompletarFuenteDeDatosPorNombre = async (
  nombre,
  valorFiltro
) => {
  if (!nombre) {
    return []
  }
  let opciones = await dbComercial.table('FuentesDeDatos').get({Nombre: nombre})

  let opcionesFiltradas = opciones.Opciones.filter(
    (x) => x.nombre.toUpperCase().indexOf(valorFiltro?.toUpperCase()) > -1
  )
  return Promise.resolve(opcionesFiltradas)
}

export const ObtenerAutocompletarFuenteDeDatosPorId = async (
  nombre,
  valorFiltro
) => {
  if (!nombre) {
    return null
  }
  let opciones = await dbComercial.table('FuentesDeDatos').get({Nombre: nombre})

  let opcionFiltrada =
    opciones.Opciones.find((x) => x.identificacion === valorFiltro) ?? null
  return Promise.resolve(opcionFiltrada)
}
