import React, {useContext} from 'react'
import {SeleccionInformacionInputRadio} from '../../inputs'
import {ContextoUnificacionDeDatos} from '../../../contexto'

const SeleccionarDatosCorrectos = ({datos}) => {
  const contextoUnificacionDeDatos = useContext(ContextoUnificacionDeDatos)

  const obtenerDatosModificados = (arreglo, seleccionado, valorAModificar) => {
    return arreglo.map((elemento) => ({
      ...elemento,
      seleccionado:
        elemento.titulo === valorAModificar
          ? seleccionado
          : elemento.seleccionado,
    }))
  }

  const modificarValorInputRadial = (
    valorAModificar,
    correcto,
    datosParaModificar
  ) => {
    const [clavePrincipal, claveSecundaria] = correcto
      ? ['correcto', 'incorrecto']
      : ['incorrecto', 'correcto']

    const ArregloPrincipal =
      contextoUnificacionDeDatos.datosParaMostrar?.[clavePrincipal]?.[
        datosParaModificar
      ]
    const ArregloSecundario =
      contextoUnificacionDeDatos.datosParaMostrar?.[claveSecundaria]?.[
        datosParaModificar
      ]

    const datosModificadosPrincipales = obtenerDatosModificados(
      ArregloPrincipal,
      true,
      valorAModificar
    )
    const datosModificadosSecundarios = obtenerDatosModificados(
      ArregloSecundario,
      false,
      valorAModificar
    )

    contextoUnificacionDeDatos.setDatosParaMostrar((prev) => {
      return {
        ...prev,
        [clavePrincipal]: {
          ...prev[clavePrincipal],
          [datosParaModificar]: datosModificadosPrincipales,
        },
        [claveSecundaria]: {
          ...prev[claveSecundaria],
          [datosParaModificar]: datosModificadosSecundarios,
        },
      }
    })
  }

  return (
    <div className="w-full flex justify-evenly items-center flex-wrap mb-20">
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Personal
        </span>
        <div className="w-full flex justify-start items-center border border-cendiatra rounded-lg p-5 flex-wrap">
          {datos?.correcto?.datosUsuario?.map((dato) => (
            <div
              key={dato.titulo}
              className="w-1/2 flex justify-center items-center my-3"
            >
              <SeleccionInformacionInputRadio
                seleccionado={dato.seleccionado}
                funcion={() =>
                  modificarValorInputRadial(dato.titulo, true, 'datosUsuario')
                }
                titulo={dato.titulo}
                valor={dato.contenido}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Personal
        </span>
        <div className="w-full flex justify-start items-center border border-cendiatra rounded-lg p-5 flex-wrap">
          {datos?.incorrecto?.datosUsuario?.map((dato) => (
            <div
              key={dato.titulo}
              className="w-1/2 flex justify-center items-center my-3"
            >
              <SeleccionInformacionInputRadio
                seleccionado={dato.seleccionado}
                funcion={() =>
                  modificarValorInputRadial(dato.titulo, false, 'datosUsuario')
                }
                titulo={dato.titulo}
                valor={dato.contenido}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Dirección
        </span>
        <div className="w-full flex justify-start items-center border border-cendiatra rounded-lg p-5 flex-wrap">
          {datos?.correcto?.datosDireccion?.map((dato) => (
            <div
              key={dato.titulo}
              className="w-1/2 flex justify-center items-center my-3"
            >
              <SeleccionInformacionInputRadio
                seleccionado={dato.seleccionado}
                funcion={() =>
                  modificarValorInputRadial(dato.titulo, true, 'datosDireccion')
                }
                titulo={dato.titulo}
                valor={dato.contenido}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Dirección
        </span>
        <div className="w-full flex justify-start items-center border border-cendiatra rounded-lg p-5 flex-wrap">
          {datos?.incorrecto?.datosDireccion?.map((dato) => (
            <div
              key={dato.titulo}
              className="w-1/2 flex justify-center items-center my-3"
            >
              <SeleccionInformacionInputRadio
                seleccionado={dato.seleccionado}
                funcion={() =>
                  modificarValorInputRadial(
                    dato.titulo,
                    false,
                    'datosDireccion'
                  )
                }
                titulo={dato.titulo}
                valor={dato.contenido}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SeleccionarDatosCorrectos
