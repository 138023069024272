import React from 'react'
import InputRadio from './InputRadio'

const SeleccionInformacionInputRadio = ({
  seleccionado,
  funcion = () => {},
  titulo,
  valor,
}) => {
  return (
    <div className="w-full flex justify-start items-center my-2.5px flex-wrap    ">
      <div className="w-1/12 flex justify-start items-center">
        <InputRadio seleccionado={seleccionado} funcion={funcion} />
      </div>
      <div className="w-11/12 flex justify-start items-center">
        <span className="text-cendiatra-verde-2  text-lg">{titulo}</span>
      </div>
      <div className="w-1/12 flex justify-start items-center"></div>
      <div className="w-11/12 flex justify-start items-center">
        <span className="text-cendiatra-gris-2 text-lg uppercase ">
          {valor}
        </span>
      </div>
    </div>
  )
}

export default SeleccionInformacionInputRadio
