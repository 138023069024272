import {getRestApi} from './ApiAxios'

export const BusquedaDocumentosParaUnificar = async (datosUsuarios) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'gato-api-function/BusquedaDocumentosParaUnificar',
    datosUsuarios
  )
}

export const ObtenerAtencionesPorUsuarioIds = async (usuariosIds) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'gato-api-function/ObtenerAtencionesPorUsuarioIds',
    usuariosIds
  )
}

export const UnificarInformacionUsuario = async (datosParaUnificar) => {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'gato-api-function/UnificarInformacionUsuario',
    datosParaUnificar
  )
}
