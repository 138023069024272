import React from 'react'
import {Input} from '../../inputs'
import BotonConImagen from '../../BotonConImagen'
import removeButtonImg from '../../../imagenes/removeButton.png'
import addButtonImg from '../../../imagenes/addButton.png'
import clsx from 'clsx'

const ListaOpciones = ({
  opcion,
  onRemove,
  onUpdate,
  onAdd,
  index,
  esEdicion = false,
  todasLasOpciones = [],
}) => {
  const establecerEstilos = (id, valor) => {
    const yaExisteEnLista = todasLasOpciones.find(
      (elemento) =>
        elemento.id !== id &&
        elemento.valor?.toUpperCase() === valor?.toUpperCase()
    )
    const valorNoExiste = !valor
    if (esEdicion) {
      return clsx(
        'appearance-none rounded relative block w-full text-13px p-1.5 text-cendiatra-gris-3 rounded-lg border focus:z-10 focus:outline-none',
        {
          'border-cendiatra-rojo-1': valorNoExiste || yaExisteEnLista,
          'border border-cendiatra-gris-2 focus:border focus:border-cendiatra ':
            !valorNoExiste && !yaExisteEnLista,
        }
      )
    }
    return 'appearance-none rounded relative block w-full text-13px p-1.5 border text-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 '
  }

  return (
    <div className="flex justify-center items-center gap-3 mt-2  border-b pb-2 border-cendiatra-gris-3">
      <Input
        estilosContenedor={'w-full'}
        estilosInput={establecerEstilos(opcion.id, opcion.valor)}
        tipo={'text'}
        placeholder={'DESCRIPCIÓN'}
        titulo={''}
        valor={opcion ? opcion.valor : ''}
        onChange={(e) => onUpdate(opcion.id, e.target.value)}
      />
      <BotonConImagen
        estilosContenedor={'w-8 pt-2'}
        imagen={removeButtonImg}
        medio={true}
        funcionAEjecutar={() => onRemove(opcion.id)}
        textoAlternativo={'eliminar'}
      />
      <BotonConImagen
        estilosContenedor={'w-8 pt-2'}
        imagen={addButtonImg}
        medio={true}
        funcionAEjecutar={() => onAdd(index)}
        textoAlternativo={'agregar opción'}
      />
    </div>
  )
}

export default ListaOpciones
