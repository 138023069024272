import React, {useState, createContext} from 'react'

export const ContextoUnificacionDeDatos = createContext('')

export const UnificacionDeDatosConContexto = ({children}) => {
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(3)
  const [resumenDatos, setResumenDatos] = useState({
    correcto: {urlFoto: '', datos: []},
    incorrecto: {urlFoto: '', datos: []},
  })
  const [datosParaMostrar, setDatosParaMostrar] = useState({
    correcto: {datosUsuario: [], datosDireccion: []},
    incorrecto: {datosUsuario: [], datosDireccion: []},
  })
  const [datosUnificados, setDatosUnificados] = useState({
    datosUsuario: [],
    datosDireccion: [],
    datosPayload: {},
  })
  return (
    <ContextoUnificacionDeDatos.Provider
      value={{
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        resumenDatos: resumenDatos,
        setResumenDatos: setResumenDatos,
        datosParaMostrar: datosParaMostrar,
        setDatosParaMostrar: setDatosParaMostrar,
        datosUnificados: datosUnificados,
        setDatosUnificados: setDatosUnificados,
      }}
    >
      {children}
    </ContextoUnificacionDeDatos.Provider>
  )
}
