import React, {useEffect, useState, useCallback, useMemo, version} from 'react'
import {
  ObtenerFuenteDeDatosPorId,
  ObtenerFuenteDeDatosPorNombre,
  ObtenerFuentesDeDatos,
} from '../../../../cache/servicios-cache'
import {BotonOpcion} from '../../../Botones'
import {ListaDesplegable} from '../../../inputs'
import TablaDinamica from '../../../TablaDinamica'
import TextoConTooltip from '../../../Atomos/TextoConTooltip'
import {EncabezadoFondo} from '../../../Tablas'

const ListaDesplegablePropiedades = ({
  nombreCampo = '',
  atributos,
  actualizarAtributo,
  actualizarAtributos,
}) => {
  const [fuentesDatos, setFuentesDatos] = useState([])
  const [fuenteDatosSeleccionada, setFuenteDatosSeleccionada] = useState(
    fuentesDatos.find((f) => f.Nombre === atributos.fuenteDatos)?.Id ?? -1
  )
  const [listaOpciones, setListaOpciones] = useState([])
  const [opcionPredeterminada, setOpcionPredeterminada] = useState(
    atributos?.valorPredeterminado ?? 0
  )

  const [versionActiva, setVersionActiva] = useState(1)

  const [opcionesVersiones, setOpcionesVersiones] = useState([])

  const obtenerInformacionInicial = useCallback(async () => {
    let fuentesDatosDB = await ObtenerFuentesDeDatos()
    fuentesDatosDB.unshift({Id: 0, Nombre: 'Elegir'})
    setFuentesDatos(fuentesDatosDB)
    setFuenteDatosSeleccionada(
      (await ObtenerFuenteDeDatosPorNombre(atributos?.fuenteDatos))?.Id ?? -1
    )

    if (Object.keys(atributos).length === 0) {
      actualizarFuenteDatos({target: {value: '0'}})
    } else if (atributos.fuenteDatos) {
      const fuenteDatosInformacion = fuentesDatosDB.find(
        (fuente) =>
          fuente.Nombre.toUpperCase() === atributos.fuenteDatos.toUpperCase()
      )
      if (fuenteDatosInformacion) {
        generarArreglo(fuenteDatosInformacion.NumeroVersiones)
        setVersionActiva(
          atributos.version ?? fuenteDatosInformacion.NumeroVersiones
        )
      }

      setFuenteDatosSeleccionada(fuenteDatosInformacion?.Id ?? 0)
      actualizarAtributos([
        {
          nombreAtributo: 'fuenteDatos',
          valorAtributo: atributos?.fuenteDatos ?? '',
        },
        {
          nombreAtributo: 'version',
          valorAtributo: fuenteDatosInformacion
            ? fuenteDatosInformacion.NumeroVersiones
            : 0,
        },
      ])
    }
  }, [])

  useEffect(() => {
    if (Object.keys(atributos).length && fuentesDatos.length) {
      const esFuenteDeDatosDb = fuentesDatos.find(
        (f) => f.Nombre === atributos.fuenteDatos
      )

      if (esFuenteDeDatosDb && !!esFuenteDeDatosDb?.Id) {
        ;(async () => {
          const datos = await ObtenerFuenteDeDatosPorId(esFuenteDeDatosDb.Id)
          const fuenteFiltradaPorVersion = datos.Opciones?.filter(
            (elemento) => {
              return !versionActiva ? true : elemento.version === versionActiva
            }
          )
          setListaOpciones(
            fuenteFiltradaPorVersion.map((dato) => ({
              id: dato?.identificacion ?? 0,
              valor: dato.nombre ?? '',
            }))
          )
        })()
      } else {
        const fuente = fuentesDatos.find(
          (f) =>
            f.Nombre?.toLowerCase().trim() === nombreCampo?.toLowerCase().trim()
        )

        if (!fuente) return
        actualizarFuenteDatos({target: {value: fuente.Id}})
      }
    }
  }, [atributos, fuentesDatos])

  useEffect(() => {
    obtenerInformacionInicial()
  }, [obtenerInformacionInicial])

  const generarArreglo = (n) => {
    const arregloVersiones = Array.from({length: n}, (_, i) => ({
      Id: i + 1,
      Nombre: i + 1,
    }))
    setOpcionesVersiones(arregloVersiones)
  }

  const filtrarVersiones = (value) => {
    if (!value) return
    setVersionActiva(+value)
    actualizarAtributos([
      {
        nombreAtributo: 'version',
        valorAtributo: +value ? +value : 0,
      },
    ])
  }

  const actualizarFuenteDatos = async (e) => {
    const valor = e.target.value
    setFuenteDatosSeleccionada(valor)
    let fuenteDatos =
      valor === 0 || valor === '0'
        ? 'Elegir manualmente'
        : fuentesDatos.find((f) => f.Id === Number(valor))?.Nombre ??
          'Elegir manualmente'

    const datosFuente = await ObtenerFuenteDeDatosPorId(+valor)

    generarArreglo(datosFuente?.NumeroVersiones)
    setVersionActiva(datosFuente?.NumeroVersiones)

    actualizarAtributos([
      {nombreAtributo: 'fuenteDatos', valorAtributo: fuenteDatos},
      {
        nombreAtributo: 'valorPredeterminado',
        valorAtributo: null,
      },
      {
        nombreAtributo: 'version',
        valorAtributo: datosFuente?.NumeroVersiones
          ? datosFuente?.NumeroVersiones
          : 0,
      },
    ])
    setOpcionPredeterminada(0)
  }

  const actualizarTextoPredeterminado = (e) => {
    const valor = e.target?.value ?? '0'
    setOpcionPredeterminada(parseInt(valor ?? 0))
    actualizarAtributos([
      {
        nombreAtributo: 'valorPredeterminado',
        valorAtributo: parseInt(valor ?? 0),
      },
    ])
  }

  const columnasTablaDinamica = useMemo(
    () => [
      {
        Header: () => (
          <EncabezadoFondo
            titulo={'Lista de opciones'}
            estilos="w-full flex justify-center items-center text-xl my-1 mx-0.5 rounded-md"
          />
        ),
        accessor: 'valor',
        className:
          'w-full flex justify-center items-center content-center h-20 my-0 mx-0.5 rounded-md text-14px uppercase',
        Cell: ({row}) => <TextoConTooltip valor={row.original.valor ?? ''} />,
      },
    ],
    []
  )

  return (
    <div className="w-full flex flex-wrap">
      <BotonOpcion
        id={1}
        titulo={'¿Desea colocar un campo de texto largo?'}
        estaActivo={Boolean(atributos?.campoTextoLargo)}
        esHorizontal={true}
        funcionClick={() => {
          actualizarAtributo('campoTextoLargo', !atributos?.campoTextoLargo)
        }}
        centrarLabel={false}
        posicionLabel="text-left"
      />
      <div className="w-full flex justify-between items-center">
        <div className="w-6/12 flex justify-center items-center flex-wrap ">
          <div className="flex flex-wrap w-6/12 text-cendiatra-verde-2 text-14px pl-2 mt-3">
            <label className="flex flex-wrap w-full align-middle content-center">
              FUENTE DE DATOS
            </label>
          </div>
          <ListaDesplegable
            onChange={actualizarFuenteDatos}
            estilosContenedor={'w-6/12 mt-3'}
            estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${
              fuenteDatosSeleccionada !== '' || fuenteDatosSeleccionada >= 0
                ? 'border-cendiatra'
                : 'border-cendiatra-rojo-1'
            } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
            opciones={fuentesDatos}
            valor={fuenteDatosSeleccionada}
            placeholder={'SELECCIONAR'}
            deshabilitarElegir={true}
          />
        </div>
        <div
          className={`w-5/12 ${
            !+fuenteDatosSeleccionada ? 'hidden' : 'flex'
          } justify-end items-center flex-wrap`}
        >
          <div className="flex flex-wrap justify-evenly w-2/12 text-cendiatra-verde-2 text-14px pl-2 mt-3">
            <label className="flex flex-wrap w-auto align-middle content-center normal-case">
              Versión
            </label>
          </div>
          <ListaDesplegable
            estilosContenedor={'w-3/12 mt-3'}
            estilosLista={`appearance-none rounded relative block w-full  p-1.5 border border-cendiatra ${
              !versionActiva ? 'bg-cendiatra-gris-2 pointer-events-none' : ''
            } text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
            funcion={(e) => filtrarVersiones(e.target.value)}
            opciones={opcionesVersiones}
            valor={versionActiva}
            placeholder={'SELECCIONAR'}
            // deshabilitarElegir={true}
          />
        </div>
      </div>
      <div className="flex flex-wrap w-1/2 text-cendiatra-verde-2 text-14px pl-2 pt-3">
        <label className="flex flex-wrap w-full text-left content-center align-middle">
          Elija una opción como texto predeterminado
        </label>
      </div>
      <ListaDesplegable
        onChange={actualizarTextoPredeterminado}
        estilosContenedor={'w-1/2 mt-3'}
        estilosLista={`appearance-none rounded relative block w-full  p-1.5 border ${'border-cendiatra'} text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm`}
        opciones={listaOpciones.map((opcion) => ({
          Id: opcion.id,
          Nombre: opcion.valor,
        }))}
        valor={opcionPredeterminada}
        placeholder={'SELECCIONAR'}
      />
      {parseInt(fuenteDatosSeleccionada) > 0 ? (
        <div className="w-full p-3 rounded-lg shadow-2xl">
          <TablaDinamica
            columns={columnasTablaDinamica}
            data={listaOpciones}
            paginacion={true}
            filasPorPagina={5}
          />
        </div>
      ) : null}
    </div>
  )
}

export default ListaDesplegablePropiedades
