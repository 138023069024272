export const estadoAtencionConstantes = {
  CANCELADA: '6',
  'EN PROCESO PENDIENTE POR CERRAR': '2',
  ATENDIDA: 3,
  REGISTRADA: 1,
  'EN PROCESO DE MODIFICACIÓN': 4,
}

export const ESTADO_ATENCION_UNIFICACION_DATOS = {
  1: 'Registrada',
  2: 'En proceso',
  3: 'Atendida',
  4: 'En proceso de modificación',
  6: 'Cancelada',
}
