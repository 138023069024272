import React from 'react'
import Boton from './Boton'
import BotonConImagen from '../BotonConImagen'

const BarraBotones = ({
  estilosContenedor,
  listaBotones,
  listaBotonesLaterales,
}) => {
  return (
    <div className={`${estilosContenedor} `}>
      <div className="w-11/12 flex justify-center items-center -mr-16">
        {listaBotones.map((boton, index) => (
          <Boton
            titulo={boton.nombre}
            habilitado={boton.habilitado}
            colorPorDefecto={'bg-cendiatra-gris-placeholder'}
            colorActivo={'bg-cendiatra'}
            tipo={'button'}
            alto={'h-10 text-13px '}
            ancho={'w-28'}
            funcionCLick={boton.funcion}
            key={index}
          />
        ))}
      </div>
      <div className="w-1/12 flex justify-center items-center">
        {listaBotonesLaterales.map((boton, index) => (
          <BotonConImagen
            estilosContenedor={
              'w-10 h-10  text-8px text-cendiatra text-center mx-2 -mt-12'
            }
            estilosImagen={'w-8 h-8'}
            imagen={boton.imagen}
            funcionAEjecutar={boton.funcion}
            textoAlternativo={boton.nombre}
            texto={boton.nombre}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}

export default BarraBotones
