import React, {useEffect, useContext, useState} from 'react'
import FilaAtencionAsociada from './FilaAtencionAsociada'
import {ContextoUnificacionDeDatos} from '../../../contexto'
import {ObtenerAtencionesPorUsuarioIds} from '../../../microservicios'

const UnificacionAtencionesAsociadas = ({numeroPagina}) => {
  const [atencionesUsuario, setAtencionesUsuario] = useState({
    correcto: [],
    incorrecto: [],
  })

  const contextoUnificacionDeDatos = useContext(ContextoUnificacionDeDatos)

  useEffect(() => {
    const obtenerDatosAtenciones = async () => {
      const {idUsuarioCorrecto, idUsuarioIncorrecto} =
        contextoUnificacionDeDatos.datosParaMostrar
      try {
        const payloadUsuarios = {
          usuarioIds: [idUsuarioCorrecto, idUsuarioIncorrecto],
          cantidad: 10,
        }
        const response = await ObtenerAtencionesPorUsuarioIds(payloadUsuarios)
        if (response.status === 200) {
          setAtencionesUsuario({
            correcto:
              response.data[
                contextoUnificacionDeDatos.datosParaMostrar.idUsuarioCorrecto
              ],
            incorrecto:
              response.data[
                contextoUnificacionDeDatos.datosParaMostrar.idUsuarioIncorrecto
              ],
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (numeroPagina === contextoUnificacionDeDatos.numeroPagina) {
      obtenerDatosAtenciones()
    }
  }, [contextoUnificacionDeDatos.numeroPagina])

  return (
    <div className="w-full flex justify-evenly items-start mb-32">
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5 flex-wrap">
          <div className="w-4/12 flex justify-start  items-center text-cendiatra">
            <span className="w-6/12 text-center ">N° Atención </span>
            <span className="w-6/12 text-center "> Fecha </span>
          </div>
          <div className="w-4/12 flex justify-start items-center"></div>
          <div className="w-4/12 flex justify-end items-center text-cendiatra pr-16">
            EMPRESA
          </div>
          <FilaAtencionAsociada datos={atencionesUsuario?.correcto} />
        </div>
      </div>
      <div className="w-5/12 flex justify-cneter items-center flex-wrap ">
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5 flex-wrap">
          <div className="w-4/12 flex justify-start  items-center text-cendiatra">
            N° Atención Fecha
          </div>
          <div className="w-4/12 flex justify-start items-center"></div>
          <div className="w-4/12 flex justify-end items-center text-cendiatra pr-16">
            EMPRESA
          </div>
          <FilaAtencionAsociada datos={atencionesUsuario?.incorrecto} />
        </div>
      </div>
    </div>
  )
}

export default UnificacionAtencionesAsociadas
