import React, {useEffect, useState} from 'react'
import {ContenedorPagina} from '../../../componentes/paginas/ContenedorPagina'
import {RUTAS_APP, TEXTO_VISUAL} from '../../../constantes'
import Busqueda from '../../../componentes/Administrador/fuenteDatos/Busqueda'
import TablaFuenteDatos from '../../../componentes/Administrador/fuenteDatos/TablaFuenteDatos'

const FuenteDatos = () => {
  const [datos, setDatos] = useState([])
  const [actualizarFuentes, setActualizarFuentes] = useState(false)

  useEffect(() => {
    if (!actualizarFuentes) return
    setActualizarFuentes(!actualizarFuentes)
  }, [actualizarFuentes])

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.MOTOR_DE_PLANTILLAS
          .CREAR_EDITAR_FUENTE_DATOS
      }
      navegacion={RUTAS_APP.MAIN}
      estilosContenedor="w-1/2"
    >
      <div className="w-full bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center mb-16 flex-wrap">
        <Busqueda setDatos={setDatos} actualizarFuentes={actualizarFuentes} />
        <div className="w-full bg-cendiatra text-white justify-center items-center rounded-xl flex flex-wrap p-2 text-xl font-normal mt-5 mb-5">
          Campos encontrados
        </div>
        <TablaFuenteDatos
          datos={datos}
          funcionEjecutarSatisfactoria={() => setActualizarFuentes(true)}
        />
      </div>
    </ContenedorPagina>
  )
}

export default FuenteDatos
