import {getRestApi} from './ApiAxios'

export async function AgregarVersionReferencia(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PUT',
    url: 'gato-api-function/AgregarVersionReferencia',
    data: payload,
  })
}

export async function CrearTiposReferenciaYReferencias(payload) {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'PUT',
    url: 'gato-api-function/CrearTiposReferenciaYReferencias',
    data: payload,
  })
}

export async function ObtenerFuentesDeDatos() {
  const apiReq = await getRestApi()
  return await apiReq.get('gato-api-function/ObtenerFuentesDeDatos')
}
