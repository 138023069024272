import React, {useContext, useEffect, useState} from 'react'
import Boton from '../../Botones/Boton'
import {useForm, Controller} from 'react-hook-form'
import {Input, ListaDesplegable} from '../../inputs'
import clsx from 'clsx'
import AgregarOpciones from './AgregrarOpciones'
import {ContextApplication} from '../../../contexto'
import {CrearTiposReferenciaYReferencias} from '../../../microservicios/FuenteDeDatos'
import BotonConImagen from '../../BotonConImagen'
import closeImg from '../../../imagenes/Salir.svg'

const Crear = ({
  funcionLimpiarCampos = () => {},
  verificarExistenciaNombre = (nombre) => {
    return false
  },
}) => {
  const {
    setError,
    getValues,
    control,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nombre: '',
    },
  })

  const [opciones, setOpciones] = useState([])

  const contextoAplicacion = useContext(ContextApplication)

  const {nombre} = getValues()

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  const crearFuenteDeDatos = async () => {
    const payload = {
      tipo: nombre,
      aplicaPlantilla: true,
      referencias: opciones.map((opcion, index) => ({
        identificacion: index + 1,
        nombre: opcion.valor,
      })),
    }

    funcionGuardarFuenteDatos(payload)
  }

  const funcionGuardarFuenteDatos = async (datos) => {
    try {
      const response = await CrearTiposReferenciaYReferencias(datos)
      if (response.status === 200) {
        modalCambiosAplicados()
        funcionLimpiarCampos()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const modalCancelacion = () => {
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            LA ACCIÓN SE CANCELARÁ
          </h2>
          <div className="text-cendiatra lowercase">
            <p>
              ¿<span className="capitalize">E</span>stá seguro de salir?
            </p>
            <p>Los datos no serán guardados</p>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Sí',
          click: () => {
            cerrarModal('setModal')
            cerrarModal('setModal2')
          },
        },
        {
          nombre: 'No',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const modalCreacion = () => {
    const nombreModal = getValues('nombre')

    if (verificarExistenciaNombre(nombre)) return modalElNombreYaExiste()

    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            SE CREARÁ LA FUENTE DE DATOS
          </h2>
          <h2 className="text-cendiatra w-full text-lg font-bold mb-5">
            {' '}
            {nombreModal}
          </h2>
          <div className="w-full mb-5">
            <p className="text-cendiatra font-bold">Se agregó</p>
            <p className="text-cendiatra">{opciones.length} opciones</p>
          </div>
          <div className="w-full text-cendiatra-rojo-1 lowercase mb-5">
            <p>
              <span className="capitalize">T</span>enga presente que no se podrá
              modificar el nombre
            </p>
            <p>de la fuente de datos una vez creada</p>
          </div>
          <h2 className="text-cendiatra w-full text-lg font-bold mb-5">
            {' '}
            ¿DESEA APLICAR LOS CAMBIOS?
          </h2>
        </>
      ),
      botones: [
        {
          nombre: 'Sí',
          click: () => {
            crearFuenteDeDatos()
          },
        },
        {
          nombre: 'No',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const modalCambiosAplicados = () => {
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra w-full text-2xl font-bold mb-5">
            {' '}
            CAMBIOS APLICADOS{' '}
          </h2>
          <div className="w-full text-cendiatra lowercase mb-5">
            <p>
              <span className="capitalize">S</span>e creó la fuente de Datos:
            </p>
            <p className="font-bold uppercase">{nombre}</p>
            <p className="uppercase">VERSIÓN 1</p>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            cerrarModal('setModal')
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const modalElNombreYaExiste = () => {
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            EL NOMBRE YA EXISTE
          </h2>
          <div className="w-full text-cendiatra lowercase mb-5">
            <p>
              <span className="capitalize">D</span>ebe elegir un nombre
              diferente para la fuente de datos
            </p>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const validarFormulario = () => {
    const nombreValido = getValues('nombre').trim() !== ''
    const opcionesValidas =
      opciones.length > 0 &&
      opciones.every((opcion) => opcion.valor.trim() !== '')
    return nombreValido && opcionesValidas
  }

  useEffect(() => {
    if (getValues('nombre').trim() === '') {
      setError('nombre')
    }
  }, [setError, getValues])

  return (
    <div className="w-full flex justify-center items-center flex-wrap -mt-10">
      <div className="w-full flex justify-end h-7 -mt-10">
        <BotonConImagen
          estilosImagen={'h-7'}
          imagen={closeImg}
          medio={true}
          funcionAEjecutar={() => cerrarModal('setModal')}
          textoAlternativo={'cerrar'}
        />
      </div>
      <h2 className="text-cendiatra-rojo-1 font-bold text-xl flex flex-col w-full justify-items-start justify-start text-left">
        CREACIÓN
      </h2>

      <div className={`flex justify-center items-end w-full text-left`}>
        <Controller
          name="nombre"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              erroresFormulario={errors}
              mostrarErrores={true}
              estilosContenedor={'w-1/2 mb-2'}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full p-1.5 border text-cendiatra font-bold rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 text-xl',
                {
                  'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1':
                    Object.keys(errors).includes('nombre'),
                  'focus:border-cendiatra border-cendiatra':
                    !Object.keys(errors).includes('nombre'),
                }
              )}
              tipo={'text'}
              placeholder={''}
              titulo={'Nombre'}
              onChange={onChange}
              valor={value}
            />
          )}
        />
      </div>

      <div className="flex flex-row w-full justify-end align-middle items-center mt-5">
        <span className="capitalize text-sm">Versión</span>
        <Controller
          name="version"
          control={control}
          render={({field: {onChange}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'flex mx-2'}
              estilosLista={
                'appearance-none rounded border border-cendiatra relative block w-full bg-cendiatra-gris-2  p-1.5 border  text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm'
              }
              opciones={[{Id: 1, Nombre: '1'}]}
              titulo={``}
              valor={'1'}
              deshabilitado={true}
              mostrarTextBlanco={true}
            />
          )}
        />
      </div>

      <AgregarOpciones opciones={opciones} setOpciones={setOpciones} />

      <div className="flex w-full mt-5 justify-center -mb-5">
        <Boton
          titulo="Regresar"
          lineaPrimerCaracter={false}
          habilitado={true}
          funcionCLick={modalCancelacion}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={''}
          tipo={'button'}
          tamanoTexto={'text-cendiatra underline'}
          ancho={'w-2/12'}
          alto={'h-9'}
        />
        <div className="mr-36"></div>
        <Boton
          titulo="Guardar"
          habilitado={validarFormulario()}
          funcionCLick={modalCreacion}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          ancho={'w-2/12'}
          alto={'h-9'}
        />
      </div>
    </div>
  )
}

export default Crear
