import React, {useContext, useMemo, useState, useEffect} from 'react'
import {ContextoGestionOtrosServicios} from '../../contexto'
import {HeaderTablaPrecios, TablaPrecios} from '../Tarifario'
import {CeldaEditableTiquetera} from '../CeldaEditableTiquetera'
import {InputConBotonPrecios, ListaDesplegable} from '../inputs'
import {OPCIONES_PRECIO, OPCIONES_PRECIO_STRING} from '../../constantes'
import {obtenerCeroSiEsNullOVacio} from '../../utilidades/funcionesComunes'
import clsx from 'clsx'

const FormularioAgregarPreciosTiqueteras = ({
  desplegarFila,
  indice,
  datos,
  setDatos,
}) => {
  const contextoGestionOtrosServicios = useContext(
    ContextoGestionOtrosServicios
  )

  const [todosLosPreciosVacios, setTodosLosPreciosVacios] = useState(true)

  const [existeNuevoPrecio, setExisteNuevoPrecio] = useState(false)

  const [informacionFila, setInformacionFila] = useState()

  const [valorParaReplicar, setValorParaReplicar] = useState(0)

  const [infomacionInicial, setInformacionInicial] = useState([])

  useEffect(() => {
    setInformacionFila(contextoGestionOtrosServicios.datosServicios[indice])
  }, [contextoGestionOtrosServicios.datosServicios])

  useEffect(() => {
    validarPreciosLlenos()
  }, [informacionFila])

  useEffect(() => {
    setInformacionInicial(contextoGestionOtrosServicios.datosServicios[indice])
  }, [])

  const validarPreciosLlenos = () => {
    if (!informacionFila?.id) {
      const existenPreciosQueNoEstanVacios =
        informacionFila?.preciosCiudades?.informacionCiudades?.some(
          (item) => item.precio
        )
      setTodosLosPreciosVacios(!existenPreciosQueNoEstanVacios)
    } else {
      const existeUnPrecioNuevo =
        informacionFila?.preciosCiudades?.informacionCiudades?.some(
          (item) => item.nuevoPrecio
        )
      setExisteNuevoPrecio(existeUnPrecioNuevo)
    }
  }

  const listaColumnas = [
    {
      Header: HeaderTablaPrecios(''),
      accessor: 'nombre',
      className: 'w-3/12',
    },
    {
      Header: HeaderTablaPrecios('PRECIO ACTUAL'),
      accessor: 'precio',
      Cell: CeldaEditableTiquetera,
      className: 'w-3/12',
      deshabilitado: Boolean(informacionFila?.id),
    },
    {
      Header: HeaderTablaPrecios('PRECIO NUEVO'),
      accessor: 'nuevoPrecio',
      Cell: CeldaEditableTiquetera,
      className: 'w-4/12',
    },
  ]

  const crearArregloServiciosModificados = (
    indice,
    arreglo,
    nombrePropiedad,
    valor
  ) => {
    if (arreglo[indice].id) {
      contextoGestionOtrosServicios.setServiciosModificados((prev) => {
        const index = prev.findIndex((item) => item.id === arreglo[indice].id)
        if (index !== -1) {
          return prev.map((item) =>
            item.id === arreglo[indice].id
              ? {...item, nombre: arreglo[indice].nombre}
              : item
          )
        } else {
          return [
            ...prev,
            {id: arreglo[indice].id, nombre: arreglo[indice].nombre},
          ]
        }
      })
    } else {
      const servicioExistente =
        contextoGestionOtrosServicios.serviciosNuevos.find(
          (servicio) => servicio.indice === indice
        )
      if (servicioExistente) {
        contextoGestionOtrosServicios.setServiciosNuevos((prev) =>
          prev.map((servicio, i) => {
            if (indice === servicio.indice) {
              return {
                ...servicio,
                [nombrePropiedad]: valor,
              }
            }
            return servicio
          })
        )
      } else {
        contextoGestionOtrosServicios.setServiciosNuevos((prev) => [
          ...prev,
          {
            id: '',
            nombre:
              nombrePropiedad === 'nombre' ? valor : arreglo[indice].nombre,
            indice: indice,
          },
        ])
      }
    }
  }

  const actualizarPrecio = (idCiudad, valor, nombrePropiedad) => {
    const arreglo = contextoGestionOtrosServicios.datosServicios

    const esUnPrecioNuevo =
      infomacionInicial.preciosCiudades.informacionCiudades.find(
        (elemento) =>
          elemento.ciudadId === idCiudad && elemento.nuevoPrecio !== valor
      )

    if (
      arreglo[indice].id &&
      nombrePropiedad === 'nuevoPrecio' &&
      Boolean(esUnPrecioNuevo)
    ) {
      crearArregloServiciosModificados(indice, arreglo, nombrePropiedad, valor)
    }
    const nuevoArreglo = arreglo.map((servicio, i) => {
      if (i === indice) {
        return {
          ...servicio,
          preciosCiudades: {
            ...servicio.preciosCiudades,
            informacionCiudades:
              servicio?.preciosCiudades?.informacionCiudades.map((ciudad) => {
                if (ciudad.ciudadId === idCiudad) {
                  return {
                    ...ciudad,
                    [nombrePropiedad]: valor,
                  }
                } else {
                  return ciudad
                }
              }),
          },
        }
      } else {
        return servicio
      }
    })
    contextoGestionOtrosServicios.setDatosServicios(nuevoArreglo)
  }

  const actualizarValorParaReplicar = (e) => {
    setValorParaReplicar(obtenerCeroSiEsNullOVacio(e.target.value))
  }

  const replicarPrecios = () => {
    const arreglo = contextoGestionOtrosServicios.datosServicios

    const nombrePropiedad = informacionFila?.id ? 'nuevoPrecio' : 'precio'
    crearArregloServiciosModificados(
      indice,
      arreglo,
      nombrePropiedad,
      valorParaReplicar
    )
    const nuevoArreglo = arreglo.map((servicio, i) => {
      if (i === indice) {
        return {
          ...servicio,
          preciosCiudades: {
            ...servicio.preciosCiudades,
            informacionCiudades:
              servicio?.preciosCiudades?.informacionCiudades.map((ciudad) => {
                return {
                  ...ciudad,
                  [nombrePropiedad]: valorParaReplicar,
                }
              }),
          },
        }
      } else {
        return servicio
      }
    })
    contextoGestionOtrosServicios.setDatosServicios(nuevoArreglo)
  }

  const modificarPrecioAPartirDe = (valor) => {
    let arreglo = contextoGestionOtrosServicios.datosServicios
    const nuevoArreglo = arreglo.map((servicio, i) => {
      if (i === indice) {
        return {
          ...servicio,
          preciosCiudades: {
            ...servicio.preciosCiudades,
            precioAPartirDe: valor,
          },
        }
      } else {
        return servicio
      }
    })
    contextoGestionOtrosServicios.setDatosServicios(nuevoArreglo)
  }

  return (
    <div
      className={clsx(
        'w-full justify-center items-center border border-cendiatra-verde-2 border-t-0 h-auto rounded-2xl rounded-tr-sm rounded-tl-sm -mt-3 pt-5 flex-wrap',
        {flex: desplegarFila, hidden: !desplegarFila}
      )}
    >
      <div className="w-full flex justify-between items-center flex-wrap">
        <div className="w-full flex justify-center items-center my-5">
          <div className="w-3/12 flex justify-center items-center">
            {informacionFila?.preciosCiudades?.precioAPartirDe ? (
              <span
                className={clsx('w-full rounded-lg text-center', {
                  'bg-yellow-200':
                    +informacionFila?.preciosCiudades?.precioAPartirDe ===
                    +OPCIONES_PRECIO_STRING.INMEDIATO,
                  'bg-cendiatra-gris-5 text-cendiatra':
                    +informacionFila?.preciosCiudades?.precioAPartirDe !==
                    +OPCIONES_PRECIO_STRING.INMEDIATO,
                })}
              >
                {+informacionFila?.preciosCiudades?.precioAPartirDe ===
                +OPCIONES_PRECIO_STRING.INMEDIATO
                  ? '! El precio será asignado de inmediato'
                  : '! El precio será asignado a partir de mañana'}
              </span>
            ) : null}
          </div>
        </div>
        <div className="w-4/12 flex justify-center items-center ml-20 my-10">
          <InputConBotonPrecios
            estilosContenedor={'w-full text-lg'}
            estilosInput={`flex items-center justify-between rounded relative block w-full  py-1.5 pl-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg`}
            titulo={`REPLICAR PRECIO A TODAS LAS CIUDADES`}
            placeholder={'$0,00'}
            funcionInput={actualizarValorParaReplicar}
            funcionBoton={() => replicarPrecios()}
            tipoBoton={'submit'}
            habilitado={Boolean(valorParaReplicar)}
            valorInput={valorParaReplicar}
            tituloBoton="Replicar"
          />
        </div>

        <div className="w-4/12 flex justify-center items-center text-cendiatra-verde-2 font-bold h-full text-center p-1">
          <ListaDesplegable
            estilosContenedor={'w-full'}
            estilosLista={clsx(
              'appearance-none rounded-xl relative block w-full p-1 border  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
              {
                'border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1 ':
                  (!informacionFila?.preciosCiudades?.precioAPartirDe &&
                    !todosLosPreciosVacios) ||
                  (existeNuevoPrecio &&
                    !informacionFila?.preciosCiudades?.precioAPartirDe),
                'border-cendiatra focus:border-cendiatra':
                  informacionFila?.preciosCiudades?.precioAPartirDe ||
                  todosLosPreciosVacios,
                'text-white bg-cendiatra-gris-2 pointer-events-none':
                  (todosLosPreciosVacios ||
                    !contextoGestionOtrosServicios.datosServicios[indice].id) &&
                  !existeNuevoPrecio,
              }
            )}
            mostrarTextBlanco={Boolean(
              !contextoGestionOtrosServicios.datosServicios[indice].id
            )}
            titulo={'Precio a partir de*'}
            opciones={OPCIONES_PRECIO}
            valor={informacionFila?.preciosCiudades?.precioAPartirDe}
            onChange={(e) => modificarPrecioAPartirDe(e.target.value)}
          />
        </div>
      </div>
      <span className="w-full text-center text-cendiatra mb-10 text-2xl">
        CIUDADES CENDIATRA
      </span>
      <TablaPrecios
        columns={
          informacionFila?.id
            ? listaColumnas
            : listaColumnas.filter(
                (elemento) => elemento.accessor !== 'nuevoPrecio'
              )
        }
        titulo={'CIUDADES CENDIATRA'}
        data={
          contextoGestionOtrosServicios?.datosServicios[indice]?.preciosCiudades
            ? contextoGestionOtrosServicios?.datosServicios[
                indice
              ]?.preciosCiudades?.informacionCiudades.sort(
                (a, b) => a.ciudadId - b.ciudadId
              )
            : contextoGestionOtrosServicios.ciudadesTiqueteras.sort(
                (a, b) => a.ciudadId - b.ciudadId
              )
        }
        actualizarDatos={actualizarPrecio}
      />
    </div>
  )
}

export default FormularioAgregarPreciosTiqueteras
