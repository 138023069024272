/**
 * Permite construir los estilos para el compoennte Input de acuerdo a errores y estado (habilidato o no)
 * @param {any} erroresFormulario Errores del formulario creado con el hook useForm.
 * @param {string} nombre Nombre del campo.
 * @param {boolean} deshabilitado Indica si el input está deshabilitado.
 * @returns Texto con estilos para el Input.
 */
export const construirEstilosInput = (
  erroresFormulario,
  nombre,
  deshabilitado = true
) => {
  let clasesCss =
    'appearance-none relative block w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm my-2 '

  if (deshabilitado) {
    clasesCss +=
      ' border-gris placeholder-white text-white focus:border-cendiatra bg-gris'
  } else if (
    typeof nombre === 'string' &&
    erroresFormulario !== undefined &&
    erroresFormulario[nombre] !== undefined
  ) {
    clasesCss +=
      ' border-red-600 border-red-600 placeholder-gray-500 text-gray-900'
  } else {
    clasesCss +=
      'border-cendiatra border-cendiatra placeholder-gray-500 text-gray-900'
  }

  return clasesCss
}
