import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ObtenerFuenteDeDatosPorNombre} from '../../../cache/servicios-cache'
import {ContextoFormulario} from '../../../contexto'
import {AreaDeTexto, ListaDesplegable} from '../../inputs'

const CampoListaDesplegable = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const versionFuenteDatos = useMemo(() => {
    return campo?.atributos?.version ?? 1
  }, [campo])

  const [opciones, setOpciones] = useState([])
  const valor =
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const valorObservacion =
    contextoFormulario.valoresObservaciones?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const manejarCambioValor = (evt) => {
    if (readOnly) return

    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: evt.target.value,
        },
      },
    })
  }

  const manejarCambioObservacion = (evt) => {
    if (readOnly) return

    contextoFormulario.setValoresObservaciones({
      ...contextoFormulario.valoresObservaciones,
      [accesoPestana]: {
        ...(contextoFormulario.valoresObservaciones?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresObservaciones?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: evt.target.value,
        },
      },
    })
  }

  const configurarOpciones = async () => {
    if (!campo.atributos || !Object.keys(campo.atributos).length) return

    if (
      campo.atributos?.listaOpciones &&
      campo.atributos?.listaOpciones.length &&
      campo.atributos.fuenteDatos.toUpperCase() === 'ELEGIR MANUALMENTE'
    ) {
      setOpciones(
        campo.atributos.listaOpciones.map((opcion) => ({
          Id: opcion.id,
          Nombre: opcion.valor,
        }))
      )
    } else {
      const fuenteDeDatos = await ObtenerFuenteDeDatosPorNombre(
        campo.atributos.fuenteDatos
      )
      let opciones = fuenteDeDatos?.Opciones ?? []

      setOpciones(
        opciones
          .filter((f) =>
            !!versionFuenteDatos ? f.version === versionFuenteDatos : true
          )
          .map((x) => ({
            Id: x.identificacion,
            Nombre: x.nombre,
          })) ?? []
      )
    }
  }

  useEffect(() => {
    configurarOpciones()
  }, [campo])

  useEffect(() => {
    if (
      campo?.atributos?.hasOwnProperty('valorPredeterminado') &&
      contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
        campo?.tituloCampo
      ] === undefined
    ) {
      contextoFormulario.setValoresCampos((prv) => ({
        ...prv,
        [accesoPestana]: {
          ...(prv?.[accesoPestana] ?? {}),
          [accesoSeccion]: {
            ...(prv?.[accesoPestana]?.[accesoSeccion] ?? {}),
            [campo.tituloCampo]: campo.atributos.valorPredeterminado ?? '',
          },
        },
      }))
    }
  }, [
    campo,
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ],
  ])
  return (
    <>
      <div className="w-full flex flex-wrap">
        <ListaDesplegable
          funcion={null}
          estilosContenedor={'w-full pb-1.5'}
          estilosLista={`appearance-none rounded w-full  p-1.5 border
           ${
             campo?.requerido && !valor
               ? 'border-cendiatra-rojo-1'
               : 'border-cendiatra'
           }
           text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
          opciones={opciones}
          onChange={manejarCambioValor}
          valor={valor}
          deshabilitado={readOnly}
          readOnly={readOnly}
        />
        {campo?.atributos?.campoTextoLargo ? (
          <AreaDeTexto
            placeholder={'Descripción (texto largo)'}
            estilosContenedor={'w-full'}
            estilosAreaDeTexto={
              'appearance-none rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            valor={valorObservacion}
            cambioValor={manejarCambioObservacion}
            readOnly={readOnly}
          />
        ) : null}
      </div>
    </>
  )
}

export default CampoListaDesplegable
