import React, {useEffect, useContext} from 'react'
import DatosPaciente from '../../recepcionista/formularioDeRegistro/confirmarDatos/DatosPaciente'
import {ContextoUnificacionDeDatos} from '../../../contexto'

const RevisarYUnificarDatos = ({numeroPagina}) => {
  const contextoUnificacionDeDatos = useContext(ContextoUnificacionDeDatos)

  const unificar = (datosCorrecto, datosIncorrecto) => {
    return [...datosCorrecto, ...datosIncorrecto].filter(
      (elemento) => elemento.seleccionado
    )
  }

  const unificarDatosSeleccionados = (datos) => {
    const unificacionDatosUsuario = unificar(
      datos.correcto.datosUsuario,
      datos.incorrecto.datosUsuario
    )
    const unificacionDatosDireccion = unificar(
      datos.correcto.datosDireccion,
      datos.incorrecto.datosDireccion
    )
    const datosMapeados = [
      ...unificacionDatosUsuario,
      ...unificacionDatosDireccion,
    ].reduce((acc, item) => {
      const key = item.titulo.replace(/[\s*.]+/g, '')
      acc[key] = item.datoInicial || item.contenido
      return acc
    }, {})

    return {
      datosUsuario: unificacionDatosUsuario,
      datosDireccion: unificacionDatosDireccion,
      datosPayload: datosMapeados,
    }
  }

  useEffect(() => {
    if (numeroPagina === contextoUnificacionDeDatos.numeroPagina) {
      contextoUnificacionDeDatos.setDatosUnificados(
        unificarDatosSeleccionados(contextoUnificacionDeDatos.datosParaMostrar)
      )
    }
  }, [contextoUnificacionDeDatos.numeroPagina])

  return (
    <div className="w-full flex justify-evenly items-center flex-wrap mb-20">
      <div className="w-10/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Personal
        </span>
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5">
          <DatosPaciente
            datos={contextoUnificacionDeDatos.datosUnificados?.datosUsuario.sort(
              (a, b) => a.id - b.id
            )}
            estilosContenedor={'w-1/2 flex-wrap'}
            estilosTitulo={'text-cendiatra text-xl mt-5'}
          />
        </div>
      </div>
      <div className="w-10/12 flex justify-cneter items-center flex-wrap ">
        <span className="w-full flex justify-center items-center text-cendiatra font-bold my-5">
          Inf. Dirección
        </span>
        <div className="w-full flex justify-center items-center border border-cendiatra rounded-lg p-5">
          <DatosPaciente
            datos={contextoUnificacionDeDatos.datosUnificados?.datosDireccion.sort(
              (a, b) => a.id - b.id
            )}
            estilosContenedor={'w-1/2 flex-wrap'}
            estilosTitulo={'text-cendiatra text-xl mt-5'}
          />
        </div>
      </div>
    </div>
  )
}

export default RevisarYUnificarDatos
