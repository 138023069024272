import React, {useContext, useEffect, useState} from 'react'
import Boton from '../../Botones/Boton'
import {useForm, Controller} from 'react-hook-form'
import {ContextApplication} from '../../../contexto'
import Crear from './Crear'
import {construirEstilosInput} from '../../../utilidades'
import {ObtenerFuentesDeDatos} from '../../../microservicios'
import {Input} from '../../inputs'
import {AgregarFuentesDeDatosACache} from '../../../cache/servicios-cache/FuentesDeDatos'

const Busqueda = ({setDatos, actualizarFuentes = false}) => {
  const {
    trigger,
    getValues,
    clearErrors,
    control,
    formState: {errors},
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nombre: '',
    },
  })

  const [fuentes, setFuentes] = useState([])
  const [actualizado, setActualizado] = useState(false)
  const [sePuedeCrear, setSePuedeCrear] = useState(false)
  const obtenerDatosIniciales = async () => {
    try {
      const fuentes = await ObtenerFuentesDeDatos()
      setFuentes(fuentes.data)
    } catch (error) {
      console.error('Error al obtener fuentes:', error)
    }
  }

  const nombre = watch('nombre')

  useEffect(() => {
    if (!actualizarFuentes) return

    const resetFuentes = async () => {
      await AgregarFuentesDeDatosACache()
      await obtenerDatosIniciales()
      setActualizado(true)
    }

    resetFuentes()
  }, [actualizarFuentes])

  useEffect(() => {
    if (!actualizado || !nombre) return
    buscarCoincidencias()
    setActualizado(false)
  }, [actualizado, fuentes, nombre])

  useEffect(() => {
    let componenteMontado = true
    trigger()
    if (componenteMontado) obtenerDatosIniciales()
    return () => {
      componenteMontado = false
    }
  }, [trigger])

  const contextoAplicacion = useContext(ContextApplication)

  const modalCrear = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <Crear
          funcionLimpiarCampos={() => {
            reset()
            trigger()
            setSePuedeCrear(false)
            AgregarFuentesDeDatosACache().then(() => obtenerDatosIniciales())
          }}
          verificarExistenciaNombre={(nombre) =>
            !!fuentes.find((f) => f.tipo.toLowerCase() === nombre.toLowerCase())
          }
        />
      ),
      botones: [],
      clasesAdicioneles: 'w-5/12 bg-cendiatra-gris-5 rounded-lg ',
    })
  }

  const obtenerUltimaVersion = (fuente) => {
    const versiones = fuente.map((opcion) => opcion.version)
    if (versiones.length === 0) return 'No hay versión'
    return Math.max(...versiones)
  }

  const buscarCoincidencias = () => {
    const textoEscrito = getValues('nombre')
    const coincidencias = fuentes.filter((fuente) =>
      fuente.tipo.toLowerCase().includes(textoEscrito.toLowerCase())
    )

    const datosFiltrados = coincidencias.map((fuente) => ({
      id: fuente.id,
      nombre: fuente.tipo,
      version: obtenerUltimaVersion(fuente.referencias),
      referencias: fuente.referencias,
    }))
    setSePuedeCrear(Boolean(!datosFiltrados.length))
    setDatos(datosFiltrados)
    clearErrors('nombre')
  }

  useEffect(() => {
    if (!getValues('nombre')) {
      setDatos([])
    }
  }, [getValues('nombre')])

  return (
    <>
      <div className="flex">
        <Controller
          name="nombre"
          control={control}
          rules={{required: true}}
          render={({field: {name, value, onChange}}) => (
            <Input
              estilosContenedor={'w-5/6 h-16'}
              estilosInput={construirEstilosInput(errors, name, false)}
              tipo={'text'}
              placeholder={'DESCRIPCIÓN'}
              titulo="Nombre*"
              valor={value}
              onChange={onChange}
            />
          )}
        />
        <div className="flex relative top-6">
          <Boton
            titulo="Buscar"
            habilitado={getValues('nombre').trim() !== ''}
            colorPorDefecto="bg-grayColor"
            colorActivo="bg-btnBg"
            funcionCLick={buscarCoincidencias}
            alto="h-9"
          />
          <div className="mr-36"></div>
          <Boton
            titulo="Crear"
            habilitado={sePuedeCrear}
            funcionCLick={modalCrear}
            colorPorDefecto="bg-grayColor"
            colorActivo="bg-btnBg"
            tipo="button"
            alto="h-9"
          />
        </div>
      </div>
    </>
  )
}

export default Busqueda
