import {useEffect, useState} from 'react'
import {NumericFormat} from 'react-number-format'
import {obtenerCeroSiEsNullOVacio} from '../utilidades/funcionesComunes'

export const CeldaEditableTiquetera = ({
  value: valorExterno,
  row: {original},
  column: {id, deshabilitado},
  actualizarDatos,
}) => {
  const [valorInterno, setValorInterno] = useState(0)

  useEffect(() => {
    let nuevoValorInterno = valorExterno

    if (
      typeof valorExterno === 'string' &&
      nuevoValorInterno.startsWith('$0')
    ) {
      nuevoValorInterno = nuevoValorInterno.substring(2)
    }

    setValorInterno(nuevoValorInterno)
  }, [valorExterno])

  const onChange = (e) => {
    if (!e.floatValue) {
      setValorInterno(0)
    } else {
      setValorInterno(e.formattedValue)
    }
    actualizarDatos(
      original?.ciudadId,
      obtenerCeroSiEsNullOVacio(e.formattedValue),
      id
    )
  }

  const validarEstilosInput = () => {
    if (deshabilitado) {
      return 'border-cendiatra bg-cendiatra-gris-2 text-white pointer-events-none'
    }
    if (!valorInterno && id === 'precio') {
      return 'border-cendiatra-rojo-1 placeholder-cendiatra-gris-1 text-cendiatra-gris-1'
    }
    return 'border-cendiatra placeholder-cendiatra-gris-1 text-cendiatra-gris-1'
  }

  return (
    <div className="w-full flex justify-center content-start relative">
      <NumericFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'$'}
        value={valorInterno}
        onValueChange={onChange}
        tabIndex={deshabilitado ? -1 : 1}
        className={`px-1 py-1 border ${validarEstilosInput()}  w-36 rounded-lg focus:outline-none focus:ring-indigo-500 z-10 sm:text-sm text-center`}
      />
    </div>
  )
}
