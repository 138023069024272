import React from 'react'
import {EncabezadoFondo} from '../../Tablas'
import moment from 'moment'
import TablaDinamica from '../../TablaDinamica'
import {FORMATOS_STANDARD} from '../../../constantes'

const TablaAtencionesAsociadas = ({
  datosTabla,
  desplegarFila,
  datosEmpresa,
}) => {
  const columnsTablaServicios = React.useMemo(
    () => [
      {
        Header: () => <EncabezadoFondo titulo={'Documento Contable'} />,
        accessor: 'documentoContable',
        className:
          'w-3/12 flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Fecha y hora'} />,
        accessor: 'fecha',
        className:
          'w-3/12  flex text-center justify-center items-center content-center h-20 text-13px uppercase',
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.row.original.fecha).format(
              FORMATOS_STANDARD?.DD_MM_YYYY_HH_mm_SEP_BARRA_DIAGONAL
            )}
          </span>
        ),
      },
      {
        Header: () => <EncabezadoFondo titulo={'Descripción'} />,
        accessor: 'nombreServicio',
        className:
          'w-3/12 flex text-center justify-center items-center content-center h-20 text-13px uppercase',
      },
      {
        Header: () => <EncabezadoFondo titulo={'Empresa'} />,
        id: 'empresa',
        className:
          'w-3/12 flex text-center justify-center items-center content-center h-20 text-13px uppercase',
        Cell: () => <span className="uppercase"> {datosEmpresa?.nombre}</span>,
      },
    ],
    []
  )
  return (
    <div
      className={`w-full ${
        desplegarFila ? 'flex' : 'hidden'
      } justify-center items-center border border-cendiatra-verde-2 border-t-0  h-auto rounded-2xl rounded-tr-sm rounded-tl-sm -mt-3 pt-5 flex-wrap`}
    >
      <TablaDinamica data={datosTabla} columns={columnsTablaServicios} />
    </div>
  )
}

export default TablaAtencionesAsociadas
