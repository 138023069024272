import React, {useContext, useEffect, useState} from 'react'
import Boton from '../../Botones/Boton'
import {useForm} from 'react-hook-form'
import AgregarOpciones from './AgregrarOpciones'
import {ContextApplication} from '../../../contexto'
import {AgregarVersionReferencia} from '../../../microservicios'

const Editar = ({
  titulo,
  idFuente,
  referencias,
  version,
  setEdicionHabilitada,
  funcionEjecutarSatisfactoria = () => {},
}) => {
  const {setError, getValues} = useForm({
    mode: 'onChange',
    defaultValues: {
      nombre: '',
    },
  })
  const [opciones, setOpciones] = useState([])

  const [cambios, setCambios] = useState({
    nuevos: [],
    modificados: [],
    eliminados: [],
  })

  const [opcionesIniciales, setOpcionesIniciales] = useState([])

  const contextoAplicacion = useContext(ContextApplication)

  const {nombre} = getValues()

  useEffect(() => {
    setOpciones(referencias)
    setOpcionesIniciales(referencias)
  }, [])

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  const editarFuenteDeDatos = async () => {
    const payload = {
      id: idFuente,
      tipo: titulo,
      aplicaPlantilla: true,
      referencias: opciones.map((opcion, index) => ({
        identificacion: index + 1,
        tipo: titulo,
        nombre: opcion.valor,
        version: +version + 1,
      })),
    }
    funcionEditarFuenteDatos(payload)
  }

  const funcionEditarFuenteDatos = async (datos) => {
    try {
      const response = await AgregarVersionReferencia(datos)
      if (response.status === 200) {
        modalCambiosAplicados()
        funcionEjecutarSatisfactoria()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const modalCancelacion = () => {
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            LA ACCIÓN SE CANCELARÁ
          </h2>
          <div className="text-cendiatra lowercase">
            <p>
              ¿<span className="capitalize">E</span>stá seguro de salir?
            </p>
            <p>Los datos no serán guardados</p>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Sí',
          click: () => {
            cerrarModal('setModal2')
            setEdicionHabilitada(false)
          },
        },
        {
          nombre: 'No',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const modalEdicion = () => {
    const elementosNuevos = cambios.nuevos.map((elemento) => {
      return opciones?.find((opcion) => opcion.id === elemento.id).valor
    })

    const elementosEliminados = cambios.eliminados.map((elemento) => {
      return opcionesIniciales?.find((opcion) => opcion.id === elemento.id)
        .valor
    })

    const elementosModificados = cambios.modificados.map((elemento) => {
      return {
        nombreInicial: opcionesIniciales?.find(
          (opcion) => opcion.id === elemento.id
        ).valor,
        nombreFinal: opciones?.find((opcion) => opcion.id === elemento.id)
          .valor,
      }
    })
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            SE REALIZARÁN CAMBIOS
          </h2>
          <h2 className="text-cendiatra w-full text-lg font-bold mb-5">
            {' '}
            {nombre}
          </h2>
          {elementosNuevos.length ? (
            <div className="w-full flex justify-center items-center flex-wrap my-5">
              <p className="text-cendiatra w-full font-bold">SE AGREGÓ</p>
              {elementosNuevos.map((elemento) => (
                <span className="w-full text-cendiatra uppercase">
                  {elemento}
                </span>
              ))}
            </div>
          ) : null}
          <div className="w-full flex justify-center items-center flex-wrap mb-5">
            {elementosModificados.map((elemento) => (
              <>
                <span className="w-full text-cendiatra ">
                  {elemento.nombreInicial}
                </span>
                <p className="text-cendiatra w-full font-bold">CAMBIÓ A</p>
                <span className="w-full text-cendiatra ">
                  {elemento.nombreFinal}
                </span>
              </>
            ))}
          </div>
          {elementosEliminados.length ? (
            <div className="w-full flex justify-center items-center flex-wrap mb-5">
              <p className="text-cendiatra-rojo-1 w-full font-bold">
                SE ELIMINARÁ
              </p>
              {elementosEliminados.map((elemento) => (
                <span className="w-full text-cendiatra-rojo-1 uppercase">
                  {elemento}
                </span>
              ))}
            </div>
          ) : null}
          <div className="w-full text-cendiatra mb-5">
            <span className="normal-case">
              La nueva versión aplica para atenciones y registros nuevos{' '}
            </span>
          </div>
          <h2 className="text-cendiatra w-full text-lg font-bold mb-5">
            {' '}
            ¿DESEA APLICAR LOS CAMBIOS?
          </h2>
        </>
      ),
      botones: [
        {
          nombre: 'Sí',
          click: () => {
            editarFuenteDeDatos()
          },
        },
        {
          nombre: 'No',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const modalCambiosAplicados = () => {
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra w-full text-2xl font-bold mb-5">
            {' '}
            CAMBIOS APLICADOS
          </h2>
          <div className="w-full text-cendiatra normal-case mb-5 flex flex-wrap">
            <span className="w-full my-1">
              Se actualizó la fuente de datos:
            </span>
            <span className="w-full font-bold uppercase my-1">{titulo}</span>
            <span className="w-full my-1">NUEVA VERSIÓN {version + 1}</span>
            <span className="w-full normal-case mt-5">
              La nueva versión aplica para atenciones y registros nuevos{' '}
            </span>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            cerrarModal('setModal')
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const validarFormulario = () => {
    const existenValoresVacios = opciones.find((elemento) => !elemento.valor)

    const tieneDuplicados = opciones.some((elemento, index, array) => {
      return (
        array.findIndex(
          (e) => e.valor.toUpperCase() === elemento.valor.toUpperCase()
        ) !== index
      )
    })

    const elArregloEsIgual =
      JSON.stringify(opciones)?.toUpperCase() ===
      JSON.stringify(opcionesIniciales)?.toUpperCase()

    return !existenValoresVacios && !tieneDuplicados && !elArregloEsIgual
  }

  useEffect(() => {
    if (!getValues('nombre').trim()) {
      setError('nombre')
    }
  }, [setError, getValues])

  return (
    <>
      <AgregarOpciones
        opciones={opciones}
        setOpciones={setOpciones}
        esEdicion={true}
        setCambios={setCambios}
        opcionesIniciales={opcionesIniciales}
      />

      <div className="flex w-full mt-5 justify-center">
        <Boton
          titulo="Regresar"
          lineaPrimerCaracter={false}
          habilitado={true}
          funcionCLick={modalCancelacion}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={''}
          tipo={'button'}
          tamanoTexto={'text-cendiatra underline'}
          ancho={'w-2/12'}
          alto={'h-9'}
        />
        <div className="mr-36"></div>
        <Boton
          titulo="Guardar"
          habilitado={validarFormulario()}
          funcionCLick={modalEdicion}
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'button'}
          ancho={'w-2/12'}
          alto={'h-9'}
        />
      </div>
    </>
  )
}

export default Editar
